@import 'src/theme.scss';

.SecondaryTabView {
  border-bottom: 1px solid $soft-gray;
  .TabItem {
    &.active{
      background: white;
      box-shadow: none;
      border: none;
      border-bottom: 4px solid $primary-color;
    }
  }
  .view {
    padding: 20px 7px;
  }
}