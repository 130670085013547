@import 'src/theme.scss';

.AmenityPage{
    display: flex;
    flex-direction: column;
    align-items: center;

    &__body{
        padding: 70px 20px 20px 20px;
        box-sizing: border-box;
        width: 100%;
        max-width: 1000px;
        text-align: center;

        hr {
            margin: 0 10px;
        }

        h1 {
            margin-bottom: 30px;
        }
    }
    &__content{
        width: 100%;
        padding: 20px 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) and (orientation: portrait) {
    .AmenityPage {
        &__body {
            margin: 10px;
        }
    }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .AmenityPage {
        &__body {
            margin: 10px;
        }
    }
}

