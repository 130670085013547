@import 'src/theme.scss';

.Popover{
    position: relative;
    &__label{
        min-width: 40px;
        overflow: auto;
        cursor: pointer;
    }
    &__close{
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background-color: $secondary-color;
        top: 10px;
        right: 10px;
        color: $total-white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
    }
}