@import 'src/theme.scss';

.AddProviderForm{
  flex: 1;
  padding: 40px;
  max-width: 650px;
  &__file{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 43px;
    &__avatar{
      width: 126px;
      img{
        width: 90px;
        height: 90px;
        border-radius: 50%;  
      }
    }
    &__container{
      display: flex;
      flex-direction: column;
      &__label{
        font-size: 14px;
      }
      &__search{
        padding-top: 10px;
      }
    }
  }
  &__item{
    margin-bottom: 30px;
    div{
      font-size: 16px;
      color: $oxford;
    }
    &__label{
      margin-bottom: 15px;
      span{
        color: $primary_color;
      }
    }
  }
  &__options{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
}

@media(max-width: 900px) {
  .AddProviderForm{
    max-width: 85%;
  }
}