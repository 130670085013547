@import 'src/theme.scss';

.tex1 { 
    height: 180px;
    width: 20%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: $total-white;
}
.tex1 p { 
    margin-bottom: 10px;
}

.tex2 { 
    height: 180px;
    width: 20%;
    display: flex;
    margin-left: 40px;
}
.tex3 { 
    height: 180px;
    width: 20%;
    display: flex;
}
.tex4 { 
    height: 180px;
    width: 40%;
    display: flex;
}

.rectangle { 
    width: 1440px;
    height: 220px;
    background-color: $dark;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

@media (max-width: 970px){
    .rectangle { 
        width: 100%;
    }
    .tex1 {
        width: 100%;
    }
    .tex2 {display: none}
    .tex3 {display: none}
    .tex4 {display: none}
}