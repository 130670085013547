@import 'src/theme.scss';

.AmenityList{
  &__items{
    padding-bottom: 3px;
    padding-left: 5px;

    &__default{
      font-size: 24px;
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $oxford;
    }
  }
  &__buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 3px;
    border-top: 1px solid $light-gray;
    margin: 10px 0px;
  }
  &__col2{
    padding: 0px 10px 3px 10px;
    &__img{
      margin-right: 9px;
    }
  }
}
@media(max-width: 1000px) {
  .AmenityList{
    &__items {
      padding-top: 10px;
      padding-bottom: 0;
      padding-left: 0;
    }
  }
}

.AmenityList__col2__cancel {
 color: $secondary-color !important;
}

.AmenityList__col2__save {
  background-color: $secondary-color !important;
}