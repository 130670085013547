@import 'src/theme.scss';

.PropertiesPage {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__image{
    display: flex;
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    img{
      height: 100%;
      max-width: 600px;
    }
  }

  &__options{
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 30px 30px 30px;
    width: 100%;
  }

  &__text{
    text-align: center;
    padding-bottom: 30px;
    * {
      color: $primary_color;
    }
  }

  &__button{
    width: 280px;
    &__button{
      width: 100%;
    }
  }
}

@media (max-width: 900px) {
  .PropertiesPage {
    &__image{
      img{
        height: auto;
        width: 100%;
        max-width: 400px;
      }
    }
    &__options{
      height: 300px;
    }
  }
}

@media (max-width: 900px) and (max-height: 500px ) {
  .PropertiesPage {
    &__image{
      max-height: 200px;
      img{
        height: 100%;
      }
    }
    &__options{
      height: 100px;
    }
  }
}

@media (min-height: 501px) and (max-height: 700px ) {
  .PropertiesPage {
    &__image{
      img{
        width: 100%;
      }
    }
    &__options{
      height: 200px;
    }
  }
}