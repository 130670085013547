@import "src/theme.scss";

.containterChangePassword{
    margin: 20px 20px;
    background-color: $total-white;
}

.ChangePassword_goback{ 
    color: $secondary-color;
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
    font-family: $main-font;
}

.ChangePassword{
    padding: 0px 400px;
    padding-top: 20px;
    padding-bottom: 50px;
    &__display{
        text-align: center;
        margin-bottom: 10px;
        &__img{
            height: 117px;
            width: 117px;
        }
        &__description{
            text-align: center;
        }
    }
    &__dataProfile{
        &__information{ 
            margin: 30px 0px;
            margin-top: 50px;
            margin-bottom: 80px;
            div{ 
                margin: 30px 0px;
            }
        }
        &__text { 
            font-family: $main-font;
            &__title4{
                font-family: $main-font;
                font-weight: 600;
                font-size: 18px;
                color: $dark;
            }
        }
        &__inputs {
            margin: 20px 0px;
        }
        &__buttons{
            display: flex;
            margin: 0px 5px;
            &__cancel{
                font-family: $main-font;
                width: 219px;
                height: 40px;
                border-radius: 4px !important;
                border: solid 1px $primary-color !important;
                color: $primary-color;
                font-size: 16px;
                cursor: pointer;
                margin: 5px 15px;
                background-color: $total-white;
            }
            &__change{
                font-family: $main-font;
                width: 219px;
                height: 40px;
                border-radius: 4px !important;
                font-size: 16px;
                cursor: pointer;
                margin: 5px 15px;
                border: none;
                background-color: $secondary-color;
                color: $total-white;
            }
        }
    }
}

.subtitle { 
    color: $secondary-color;
}