@import 'src/theme.scss';

.BuildList{
  &__default{
    font-size: 24px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $oxford;
  }
}

@media(max-width: 900px) {
  .BuildList{
    &__default{
      height: auto;
    }
  }
}