@import 'src/theme.scss';

.BudgetPending{
  position: relative;
  //padding: 10px;

  &__loader{
    height: 40px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition-duration: 0.3s;
    position: absolute;
    display: flex;
    top: 0px;
    z-index: 3;
  }

  &__none{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: rgba($primary_color, 0.1);
  }

  &__total{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 70px;
    padding-right: 30px;
    box-sizing: border-box;

    div{
      min-width: 150px;
    }
    
    &__title{
      font-size: 18px;
      font-weight: 200;
      text-align: right;
      padding-right: 10px;
    }
    
    &__amount{
      font-size: 20px;
      font-weight: 300;
      text-align: center;
    }
    
    &__opt{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 900px) {
  .BudgetPending{
    overflow: auto;
    &__none{
      min-width: 1000px;
    }
  }
}