@import 'src/theme.scss';

.SnackBar{
  font-size: 16px;
  &__detail{
    font-size: 12px;
    background-color: $total-white;
    padding: 10px;
    color: $oxford;
    margin-top: 10px;
  }
}