@import 'src/theme.scss';

.FileButton {
  box-shadow: none !important;
  input[type="file"] {
    height: 0;
    padding: 0;
  }
  .MuiFormLabel-root {
    margin-bottom: 12px;
  }
  .MuiInput-underline {
    &:before, &:after {
      content: initial;
    }
  }
  .MuiButton-root {
    align-self: flex-start;
  }
  .Mui-focused {
    & + .MuiButton-root {
      border: 1px solid #0F8570;
      background-color: rgba(15, 133, 112, 0.04);
    }
  }
  .file-name {
    margin-top: 5px;
    margin-bottom: 0;
  }
}