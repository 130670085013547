@import 'src/theme.scss';

table { 
    font-family: $main-font;
    border: hidden;
    thead {
        tr {
            text-align: left;
        }

    }
    tbody {
        border: hidden;
        tr { 
            text-align: left;
            border: hidden;
            td { 
                border: hidden;
                padding-left: 0px;
                padding-right: 160px;
                white-space: nowrap;
                padding-bottom: 10px;
                padding-top: 10px;
                font-family: $main-font;
            }
        }
    }
}
.TableProperties_name{
    font-family: $main-font;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.TableProperties_quanties {
    font-family: $main-font;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-right: 40px !important;
}