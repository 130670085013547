.row {
    flex: 1;
    display: flex;
    flex-direction: row;
    &.spacing{ 
        .col{
            padding: 0 10px;
        }
    }
}
.col {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.flex-zero { 
    flex: 0;
}
.space-between {
    justify-content: space-between;
}
.row-flex-start { 
    justify-content: flex-start;
}
.row-flex-end {
    justify-content: flex-end;
}
.row-centered {
    justify-content: center;
}

.align-center {
    align-items: center;
}
.self-center {
    align-self: center;
}