@import 'src/theme.scss';

.DashboardPage{
  width: 100%;
  &__fixed{
    position: fixed;
    width: 100%;
    z-index: 10;
  }
  .body {
    padding: 20px;
    & > p {
      margin-bottom: 20px;
    }
    .section {
      margin-bottom: 20px;
      & > .col:not(:last-child) {
        margin-right: 20px;
      }
    }
    .Table {
      .MuiTableContainer-root {
        max-height: 100vh;
        overflow: auto;
      }
    }
  }
  .table-wrapper {
    .heading-4 + button {
      margin-left: 20px;
    }
  }
}

@media(max-width: 1030px) {
  .DashboardPage {
    .SectionStatus, .SectionIncome {
      max-width: 100%;
    }
    .body .section {
      flex-direction: column;
      & > .col:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}