@import 'src/theme.scss';

.BudgetProgrammed{
  position: relative;

  &__loader{
    height: 70px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition-duration: 0.3s;
    position: absolute;
    display: flex;
    top: 0px;
    z-index: 3;
  }

  &__none{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: rgba($primary_color, 0.1);
  }
}