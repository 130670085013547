@import 'src/theme.scss';

@media (min-width: 0px) {
  .LoginPage{
    display: flex;
    flex-direction: column;
    height: 100%;

    &__containerLogo{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-image: url('../../assets/logos/img-login@2x.jpg');
      background-repeat: no-repeat;
      background-size: cover;

      &__logo{
        height: 100px;
        width: 100px;
        margin: 40px 0px 0px 40px;
      }

      &__containerIntro{
        display: flex;
        justify-content: center;
        padding: 0px 15%;
        margin-bottom: 87px;
        text-align: center;

        span {
          color: $total-white;
          font-size: 34px;
          letter-spacing: normal;
          line-height: 1.26;
        }
      }

      &__filter{
        background-color: rgba($color: $primary_color, $alpha: 0.4);
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &__panel{
          background-image: linear-gradient(to bottom, rgba($color: $dark-green, $alpha: 0.9), rgba($primary_color, $alpha: 0.87));
          box-shadow: 0px 0px 10px $dark;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px;
          width: 230px;
          height: 230px;
          position: relative;
          margin: 20px;

          img{
            width: 170px;
            height: auto;
          }
        }
      }
    }

    &__containerForm{
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      &__container{
        width: 100%;
        padding-top: 20px;
        box-sizing: border-box;
      }
    }
  }
}

@media (max-width: 900px) {
  .LoginPage{
    &__containerLogo{
      max-height: 300px;
      background: $dark-green;
      margin-bottom: 20px;
      display: none;
      &__logo{
        display: none;
      }
      &__containerIntro{
        span {
          display: none;
        }
      }
      &__filter {
        background: transparent;
        &__panel {
          padding: 0px;
          background-image: initial;
          box-shadow: initial;
          height: 20px;
          img {
            width: 50px;
          }
        }
      }
    }
    &__containerForm {
      &__container {
        padding-bottom: 20px;
      }
    }
  }
  .Remember__text{
    margin-bottom: 10px;
  }
  .Remember__container__title{
    margin-bottom: 10px;
  }
  .Remember__button button{
    margin: 0 0 30px 0;
  }

}

@media (min-width: 901px) {
  .LoginPage{
    height: 100vh;
    flex-direction: row;
    &__containerLogo{
      flex: 1;
      &__filter{
        &__panel{
          width: 330px;
          height: 330px;
          img{
            width: 200px;
            height: auto;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .LoginPage{
    flex-direction: row;
    &__containerLogo{
      flex: 1;
      &__filter{
        &__panel{
          width: 430px;
          height: 430px;
          img{
            width: 270px;
            height: auto;
          }
        }
      }
    }
  }
}
