/* Button Component Styles */
@import 'src/theme.scss';

.ButtonComponent {
  box-shadow: none !important;
  .ButtonDefault{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    white-space: nowrap;
  }
}