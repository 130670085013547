@import 'src/theme.scss';

.HistoryHeader{
  display: flex;
  flex-direction: row;
  background-color: $dark-green;
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-sizing: border-box;

  div{
    color: $total-white;
    display: flex;
    padding-left: 20px;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
  }

  &__col{
    width: 25%;
  }
  &__img{
    width: 25%;
    justify-content: center !important;
  }
}