.Subscriptions {
    padding: 20px;
    .status-types {
        margin: 20px 0;
        .SubscriptionStatus {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
    .SimpleCard {
        height: auto;
    }
    .Table {
        min-width: 1370px;
        padding-bottom: 0;
        .MuiTableContainer-root {
            max-height: calc(100vh - 245px);
        }
    }
}