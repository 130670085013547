@import 'src/theme.scss';

.NavDashboard{
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  position: relative;
  background: $total-white;
  border-bottom: 1px solid $gray;
}