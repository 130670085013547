@import 'src/theme.scss';

.Frecuency{
  width: 250px;
  padding: 10px 20px;
  box-sizing: border-box;
  &__text{
    color: $primary_color;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  &__item{
    font-size: 14px;
    color: $oxford;
    padding: 0px;
    label{
      margin: 0px;
      height: 30px;
    }
    span{
      padding-left: 0px;
    }
  }
  &__input{
    margin-top: 10px;
    width: 100%;
  }
  &__button{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}