@import 'src/theme.scss';

.BudgetAsset {
  &__header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    color: $oxford;
    &__img {
      width: 40px;
      svg {
        height: 20px;
        max-width: 20px;
        path {
          fill: transparent;
          stroke: $oxford;
        }
      }
    }
    .subtitle {
      color: $primary-color;
      white-space: nowrap;
    }
    .date {
      text-align: center;
      white-space: nowrap;
    }
  }

  &__titles{
    display: flex;
    flex-direction: row;
    background-color: $gray-5;
    &__col{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      padding: 21px 5px;
      white-space: nowrap;
      flex: 1;

      button{
        padding: 0px;
        color: $secondary_color;
      }
    }
    &__options{
      width: 25px;
    }
  }
}

@media (max-width: 900px) {
  .BudgetProgrammed{
    overflow: auto;
  }
  .BudgetAsset{
    &__header {
      box-sizing: border-box;
      min-width: 1000px;
    }
    &__titles{
      min-width: 1000px;
    }
  }
  .theBudgetConcept {
    min-width: 1000px;
  }
}