@import 'src/theme.scss';

.FoundModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 160, 154, 0.314);
  z-index: 4;
  .modal {
    background: $total-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    box-shadow: 0 9px 24px 0 rgba(98, 131, 107, 0.57);
    max-width: 700px;
    width: 100%;
    .head {
      padding: 17px 29px;
      border-bottom: 1px solid $gray;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      label {
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $black;
      }

      .material-icons {
        background: $secondary-color;
        color: $total-white;
        font-size: 33px !important;
        border-radius: 50%;
        padding: 0px!important;
        cursor: pointer;
      }
    }
    .body {
      padding: 5px 50px;
      overflow: auto;
      max-height: calc(100vh - 140px);

      .message-found {
        text-align: justify;
        padding: 0px 15px;
        .color-gray {
          color: $oxford !important;
          margin-bottom: 10px;
        }
        .color-black { 
          color: $dark !important;
        }
      }
      .form {
        padding-left: 15px;
        padding-right: 15px;
        .MuiFormControl-root {
          margin-top: 25px !important;
        }
        div[role="progressbar"] {
          margin: 10px auto;
          display: table;
        }
        &, .column {
          flex-direction: column;
          display: flex;
          & > label, .RadioGroup legend {
            color: $oxford;
            font-size: 15px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-bottom: 10px;
            margin-top: 10px;
          }
          .CurrencyInput {
            .MuiInput-root {
              border: none;
            }
          }
          .input_modal{
            margin-top: 24px;
          }
          .text_modal{
            padding-top: 18px;
            font-family: $main-font;
            font-size: 14px;
            color: gray;
          }
          .div-button{
            text-align: center;

            button {
              background-image: linear-gradient(to bottom, $secondary-color, $secondary-color);
            }
          }
          .button_modal{

            width: 150px;
            height: 40px;
          }
          .pay_method {
            label {
              span:nth-child(2) {
                font-size: 18px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $oxford;
              }
            }
          }
          .expiration-date {
            & > div {
              label {
                display: none;
                & + div {
                  margin-top: 0;
                }
              }
              &:nth-child(1) {
                width: calc(60% - 20px);
                margin-right: 20px;
              }
              &:nth-child(2) {
                width: 40%;
              }
              & > div > div {
                border-color: $oxford !important;
                &, & > div {
                  height: 35px;
                  padding: 0;
                  width: 100%;
                  color: $oxford;
                  font-size: 18px;
                  &:focus {
                    background: transparent;
                  }
                }
                & > div {
                  padding: 7px 16px;
                  height: auto;
                }
                svg {
                  border-color: $oxford;
                }
              }
            }
          }
        }
      }
      button, a {
        text-decoration: none;
        background-image: linear-gradient(153deg, $light-orange, $orange);
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $total-white;
        padding: 7.5px;
        margin-top: 20px;
        margin-bottom: 20px;
        cursor: pointer;
      }
      &.response {
        flex-direction: column;
        justify-content: center;
        align-content: center;
        display: flex;
        padding: 40px;
        p {
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.28;
          letter-spacing: normal;
          text-align: center;
          color: $oxford;
        }
        h2 {
          font-size: 32px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.6px;
          text-align: center;
        }
        &.success {
          color: $primary-color;
        }
        &.error {
          color: $pinkish-red;
        }
        .pay-info {
          flex-direction: row;
          justify-content: center;
          align-content: center;
          display: flex;
          margin-top: 20px;
          .col {
            display: flex;
            flex-direction: column;
            label {
              font-size: 18px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.11;
              letter-spacing: normal;
              color: $oxford;
              margin-bottom: 10px;
              &.highlight{
                color: $secondary-color;
              }
            }
            &.bold {
              margin-right: 40px;
              label {
                font-size: 18px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.11;
                letter-spacing: normal;
                text-align: right;
                color: $dark;
              }
            }
          }
        }
      }
    }
  }
}