@import 'src/theme.scss';

.BudgetHistory {
  &__date {
    width: 100%;
    height: 40px;
    background-color: $primary_color;
  }
}

@media (max-width: 900px) {
  .BudgetHistory {
    &__date {
      min-width: 1000px;
    }
  }
}
