.Event {
    border-radius: 5px;
    border: solid 1px #19e1bd;
    background-color: #cdf9f1;
    width: 100%;
    height: 100%;
    padding: 5px;
    flex-direction: column;
    display: flex;
    cursor: pointer;
}