@import 'src/theme.scss';

.Warning__quota{
    text-align: center;
    background-color: $secondary-color;
    padding: 10px 0px 10px 0px;
    font-size: 15px;
    color: $total-white;
}

.QuotaDatePayMount__form  { 
    margin-left: 40px;
    display: flex;
    margin-bottom: 15px;
    div {
        input { 
            margin-top: 10px;
            padding: 7px;
            text-align: center;
            border-radius: 5px;
            border: solid 2px $secondary-color;
        }

        p { 
            font-size: 13px;
            margin-top: 10px;
            color: red;
        }
         display: grid;
         margin-right: 70px;
         padding: 20px;
         text-align: center;
    }
   
}

.QuotaDatePayMount { 
    border-bottom: 1px solid $secondary-color;
    padding-bottom: 10px;
    p {
        padding-bottom: 10px;
    }
}

.inavlid-input { 
    input:disabled {
        background: $gray-4;
    }
}

