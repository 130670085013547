@import 'src/theme.scss';

.CancellationModal {
    form {
        padding: 0 70px;
        margin: 20px 0;
        label {
            text-align: center;
            padding-bottom: 20px;
        }
    }
}