@import 'src/theme.scss';

.SubscriptionStatus {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  &.labeled {
    font-size: 12px;
    border-radius: 20px;
    padding: 1px 10px;
    width: auto;
    height: auto;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}