@import 'src/theme.scss';

.ModalInt{
  height: 600px;
  transition-duration: 0.3s;
  flex: 1;
  display: flex;
  justify-content: center;
  &__min{
    height: 100px;
    transition-duration: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__container{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    max-width: 100%;
  }

  img{
    margin-bottom: 22px;
  }
  
  &__title{
    font-size: 32px;
    color: $primary_color;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  &__text{
    font-size: 18px;
    color: $oxford;
    margin-bottom: 23px;
    line-height: 1.56;
    text-align: center;
  }

  &__options{
    display: flex;
    flex-direction: row;
    button{
      margin: 0px 10px;
    }
  }
}