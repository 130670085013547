@import 'src/theme.scss';

.ItemProperties{
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 3;
  img{
    max-width: 33.5px;
    height: 33.5px;
  }
  &__title{
    padding: 10px 20px;
  }
  &__item{
    &__img{
      widows: 30px;
      max-height: 30px;
      
    }
    &__svg{
      svg {
        path {
          stroke: transparent;
          fill: $secondary-color;
        }
      }
    }
    &list{
      overflow:hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .icon-container {
      div {
        display: flex;
        flex: 1;
      }
    }
    &__label{
      display: flex;
      white-space: nowrap;
      margin: 0px 10px;
      color: $primary_color;
    }
    svg {
      path {
        stroke: transparent;
        fill: $secondary-color;
      }
    }
  }
}

.MuiIcon-root-30{
  font-size: 35px!important;
}