.EventDetail {
    align-items: flex-start;
    min-width: 220px;
    .status {
        padding: 2px 15px;
        border-radius: 20px;
        margin: 10px 0 0;
    }
    hr {
        width: 100%;
        margin: 13px 0;
    }
    svg {
        font-size: 14px;
        margin-right: 10px;
        margin-top: 3px;
    }
    .detail-box {
        margin-bottom: 10px;
    }
    label {
        text-transform: capitalize;
    }
    .row {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
    button:not(:last-child) {
        margin-right: 10px;
    }
}