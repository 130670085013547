@import 'src/theme.scss';

.StepperWizardItem{
  display: flex;
  flex-direction: row;
  align-items: center;
  &__number{
    min-width: 24px;
    min-height: 24px;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: $total-white;
    font-weight: 500;
    margin-right: 13px;

    span{
      min-height: 30px;
      min-width: 30px;
      font-size: 17px;
      border: 1.8px solid $light-gray;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $gray;
    }

    &__active{
      min-width: 34px;
      min-height: 34px;
      border: none;
      span{
        min-height: 30px;
        min-width: 30px;
        border: 2px solid $secondary-color;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $total-white;
        color: $secondary-color;
      }
    }
  }
  &__text{
    margin-right: 38px;
    text-align: center;
    * {
      color: $gray;
    }
  }
  &__line{
    height: 2px;
    width: 30px;
    border-bottom: 2px solid $secondary-color;
    margin-right: 15px;
  }
}
@media (max-width: 900px) {
  .StepperWizardItem {
    display: none;
    &.active {
      display: block;
    }
  }
}