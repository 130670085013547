@import 'src/theme.scss';

.History{
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  &__null{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: rgba($primary_color, 0.1);
  }
}