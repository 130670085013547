@import 'src/theme.scss';

.Params{
  label{
    color: $total-white;
  }
  span{
    color: $total-white;
    display: none;
  }
}
.QuotaDetail{
  flex: 1;
  display: flex;
  flex-direction: row;

  &__drawer{
    width: 110px;
  }
  
  &__options{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      background-color: $secondary-color !important;
    }
  }


  &__content{
    flex: 1;
    padding: 20px;
    max-width: calc(95vw - 230px);
    &__labelPay {
      line-height: 34px;
    }
    &__relation{
      display: flex;
      align-items: center;
    }
    &__relation_col{
      display: flex;
      flex-direction: column;
    }
    &__cost{
      font-size: 11px;
      color: $primary-color;
      text-align: center;
      margin-top: 6px;
      display: flex;
      padding-bottom: 15px;
      align-items: center;
      &__detail{
        width: 20%
      }
      //width: 20%;
    }
    &__label{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
      div{
        font-size: 12px;
        text-align: center;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: $oxford;
      }
      span{
        margin-right: 5px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: $primary-color;
      }
    }

    .table {
      overflow: auto;
      padding-bottom: 5px;
      max-height: 50vh;
      position: relative;
      & > div {
        min-width: 1000px;
      }
    }
  }
}

.note { 
  text-align: justify;
}

@media(max-width: 900px) {
  .QuotaDetail {
    flex-direction: column;
    &__drawer{
      width: 100%;
      min-height: auto;
    }
    &__content{
      max-width: calc(100vw - 50px);
      padding: 0px;
    }
  }
}
@media(max-width: 3000px) {
  .Params{
    label{
      display: none;
    }
    span{
      display: block;
    }
  }
}