@import 'src/theme.scss';

.MasiveCard{
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;

  &__instructions{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 77px;
    background-color: $eggshell-blue;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    img{
      height: 20px;
      margin-left: 5px;
      cursor: pointer;
    }
    &__cliked{
      color: $secondary_color;
      cursor: pointer;
      margin: 0px 5px;
    }        
  }
  &__zone{
    height: 155px;
    background-color: $total-white;
    border: 3px dashed $eggshell-blue;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__inactive{
      margin-bottom: 20px;
      font-size: 22px;
      color: $gray;
      transition-duration: 0.3s;
      &__none{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button{
          margin-top: 18px;
          border: 1px solid $secondary_color;
          width: 180px;
          height: 40px;
          border-radius: 5px;
          color: $secondary_color;
          font-size: 18px;
          cursor: pointer;
        }
      }
      &__file{
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span{
          font-size: 70px;
          color: $primary_color;
          margin-bottom: 10px;
        }
      }
      &__nofile{
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span{
          font-size: 70px;
          color: red;
          margin-bottom: 10px;
        }
      }
    }
    &__active{
      margin-bottom: 20px;
      font-size: 22px;
      color: $secondary_color;
      transition-duration: 0.3s;
    }
  }
  &__error{
    margin-top: 10px;
    text-align: right;
    color: red;
  }
  &__options{
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    button{
      margin-left: 10px;
    }
  }
}