@import 'src/theme.scss';

.side-menu {
  height: calc(100vh - 60px);
  padding-top: 60px;
  width: 70px;
  background-color: $total-white;
  transition: 0.5s width ease;
  z-index: 2;
  min-height: 410px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  .options {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &:hover {
    width: 250px;
    .item {
      label {
        left: 70px;
        transform: translate(0, -50%);
        opacity: 1;
      }
    }
  }
  .subtitle {
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.2px;
    color: $dark;
    max-width: 70px;
  }
  .item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    min-height: 60px;
    &, * {
      cursor: pointer;
    }
    &::after{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 4px;
      height: 60px;
      border-radius: 3px;
      z-index: 2;
    }
    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      max-width: 70px;
      background: $total-white;
      z-index: 1;
      svg {
        height: 20px;
        width: 20px;
        path {
          fill: $oxford;
          stroke: transparent;
        }
      }
    }
    label {
      color: $oxford;
      white-space: nowrap;
      left: -100%;
      position: absolute;
      top: 50%;
      transform: translate(-100%, -50%);
      transition: .5s all ease;
      z-index: 0;
      opacity: 0;
    }
    &:not(.selected):hover {
      &, .icon-container {
        background-color: $gray-5;
      }
    }
    &.selected {
      &::after {
        content: "";
        background-color: $primary_color;
      }
      label {
        color: $primary_color;
      }
      .icon-container {
        svg {
          path{
            fill: $primary_color;
            stroke: transparent;
          }
        }
      }
    }
  }
}
