@import 'src/theme.scss';

.Regulation{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__container{
    align-items: center;
  }

  &__title{
    font-size: 24px;
    color: $primary_color;
    margin-bottom: 30px;
    margin-top: 40px;
  }
  &__text{
    font-size: 18px;
    color: $oxford;
    margin-bottom: 50px;
  }
  &__upload{
    border: 1px solid $light-green;
    width: 388px;
    height: 388px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    img{
      width: 60px;
      transform: rotate(180deg);
      margin-bottom: 30px;
    }
    label{
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}

.RegulationContainer {
  margin: 20px 38px;
}

.RegulationTable {
  display: flex;
  flex: 1;
  background-color: $total-white;
  border: 1px solid $eggshell-blue;
  border-radius: 10px;
  margin-top: 20px;
  flex-direction: column;
  padding-bottom: 15px;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 8px 30px;
    background: $primary-color;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: $total-white;

    &__tab {
      width: 33%;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid $light-gray;
    padding: 8px 30px;

    &__section {
      width: 33%;

      a {
        color: $secondary-color;
        text-decoration: none;
      }
    }
  }
}

.date {
  text-align: center;
}

.link {
  text-align: right;
}
