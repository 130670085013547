@import 'src/theme.scss';

.TitleFloat{
  width: 100%;
  z-index: 1;
  position: relative;
}

.Title {
  background-color: $secondary_color;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(148, 157, 169, 0.5);
  position: fixed;
  top: 60px;

  &__back{
    flex: 0;
    padding: 5px 18px;
    font-size: 18px;
    color: $secondary_color;
    display: flex;
    flex-direction: row;
      button{
        color: $total-white;
        font-size: 16px;
        text-transform: capitalize;

      }
      cursor: pointer;
      &__text{
        position: relative;
        top: -1px;
      }
  }
  &__back_dash{
      width: 7%;
      padding: 5px 5px;
      font-size: 18px;
      color: $secondary_color;
      display: flex;
      flex-direction: row;
      margin-right: 20px;
      button{
        color: $total-white;
        font-size: 16px;
        text-transform: capitalize;

      }
      cursor: pointer;
      &__text{
        position: relative;
        top: -1px;
      }
  }
  &__title{
      flex: 1;
      padding: 5px 18px;
      font-size: 16px;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      margin-left: 15%;
      span{
        color: $total-white;
      }
      &__info{
        margin-right: 0px;
        padding: 5px 5px;
        font-size: 18px;
        button {
          color: $total-white;
        }
        span {
          font-size: 27px;
        }
      }
  }
}

//* title and spinner modification,for mobile devices *//
@media(max-width: 768px) {
  .Title__back_spinner {
    display: none;
  }

  .MuiTypography-body1-10 span {
    font-size: 16px;
  }
}

@media(max-width: 730px) {
  .Title {
    &__back{
      display: none;
      &__info{
        width: 15%;
      }
    }
    &__back_spinner{
      width: 0%;
    }
    &__back_dash{
      width: 15%;
    }

    &__title {
      width: 100%;
    }
  }
}
