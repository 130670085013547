@import 'src/theme.scss';

.Relations{
  min-height: 300px;
  &__list{
    &__cont{
      height: 100px;
      background-color: rgba($primary_color, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__opt{
    margin-bottom: 40px;
    padding-bottom: 13px;
    &__label{
      color: $secondary_color;
      cursor: pointer;
      margin-top: 13px;
    }
  }
}