@import 'src/theme.scss';

.Build{
  display: flex;
  flex-direction: column;
  text-align: center;
    
  &__container{
    padding: 60px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__button{
      margin: 20px 0px 40px 0px;
      button{
        background-image: linear-gradient(to bottom, $secondary-color, $secondary-color);
      }
    }

    &__text{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 20px;
      p{
        margin-right: 5px;
      }
      &__cliked{
        * {
          color: $secondary_color;
          cursor: pointer;
        }
      }
    }

    &__panel{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 20px;
      z-index: 0;
    }

    &__card{
      display: flex;
      flex-direction: row;
      justify-content: center;
      &__col{
        width: 45%;
        padding: 15px 15px 0px 15px;
        &__int{
          min-height: 180px;

          &__title{
            display: flex;
            flex-direction: row;
            background-color: $dark-green;
            justify-content: center;
            align-items: center;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            padding: 5px;
            margin-bottom: 20px;
            *{
              color: $total-white;
            }
          }
          &__subopc{
            div{
              color: $oxford;
            }
            img{
              margin-right: 10px;
            }
            button{
              height: 30px;
              width: 110px;
              background-color: $secondary_color;
              border: none;
              color: $total-white;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }

  }
}

@media(max-width: 900px) {
  .Build{
    &__container{
      &__panel{
        flex-direction: row;
      }
    }
  }
  .SectionCard__cube__containerText{
    font-size: 12px;
  }
}

@media(max-width: 1000px) {
  .Build{
    display: flex;
    flex-direction: column;
    text-align: center;
    &__container{
      &__card {
        flex-direction: column;
        &__col {
          width: 100%;
          margin: 0 auto;
          padding: 0;
          &__int {
            min-height: 0;
          }
          .SimpleCard {
            box-shadow: none;
          }
          &:first-child {
            margin-top: 20px;
            .SimpleCardBuild.text.buil {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
          &:last-child {
            .SimpleCard {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }
            .Build__container__card__col__int__title{
              border-top-right-radius: 0;
              border-top-left-radius: 0;
            }
            .Build__container__card__col__int__subopc {
              display: none;
            }
          }
        }
      }
    }
  }
}



@media(max-height : 570px) {
  .Build{
    display: flex;
    flex-direction: column;
    text-align: center;
    &__container{
      &__card {
        flex-direction: column;
        &__col {
          width: 75%;
          margin: 0 auto;
          padding: 0;
          &__int {
            min-height: 0;
          }
          .SimpleCard {
            box-shadow: none;
          }
          &:first-child {
            margin-top: 20px;
            .SimpleCardBuild.text.buil {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
          &:last-child {
            .SimpleCard {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }
            .Build__container__card__col__int__title{
              border-top-right-radius: 0;
              border-top-left-radius: 0;
            }
            .Build__container__card__col__int__subopc {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media(max-width: 600px) {
  .Build {
    &__container{
      &__panel{
        flex-direction: row;
      }
    }
  }
}