@import 'src/theme.scss';

.Budget{
  &__body{
    padding: 80px 35px 25px 35px;
    display: flex;
    flex-direction: column;
  }
  &Concept {
    min-width: 1000px;
  }
}
.AssetItem__body {
  overflow: auto;
  padding-bottom: 5px;
}
.BudgetInitial{
  min-height: 738px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 95px;
  &__title{
    font-size: 24px;
    color: $oxford;
    margin-bottom: 34px;
  }
  &__options{
    display: flex;
    flex-direction: row;
    button {
      margin: 15px;
    }
  }
}

.BudgetDetail, .QuotaDetail {
  display: flex;
  flex-direction: row;
  height: 100%;
  &__drawer{
    width: 110px;
    min-height: 100%;
  }
  &__col {
    flex: 1;
    max-width: calc(94vw - 180px);
  }
  &__content{
    flex: 1;
    padding: 0px 40px;
    &__options{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}
@media (max-width: 900px) {
  .BudgetDetail, .QuotaDetail {
    flex-direction: column;
  }
  .Budget{
    &__body{
      padding: 60px 0 0 0;
    }
    &Concept {
      min-width: 1000px;
    }
    .BudgetDetail__drawer {
      width: 100%;
      min-height: auto;
      position: relative;
    }
    .BudgetDetail__content {
      padding: 25px 20px;
    }
  }
  .BudgetDetail__col{
    max-width: 100%;
  }
  .BudgetDetail__content{
    padding: 25px 20px;
  }
  .BudgetDetail__drawer {
    width: 100%;
    min-height: auto;
  }
}