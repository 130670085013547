@import 'src/theme.scss';

.NewContact{
  margin-top: 24px;
  padding-bottom: 24px;
  &__title{
    font-size: 18px;
    color: $oxford;
    margin-bottom: 15px;
  }
  &__body{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-gray;
    padding: 20px 40px;
    &__container{
      max-width: 641px;
      box-sizing: border-box;
    }
    &__separator{
      margin-bottom: 15px !important;
    }
  }
  &__options{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 20px;
    button{
      margin: 0px 10px;
      width: 150px;
    }
  }
}