@import 'src/theme.scss';

.BudgetRowDetail {
    h5 {
        background-color: $secondary-color;
        color: $total-white;
        margin: 0;
        padding: 15.5px 10px;
        text-align: center;
    }
    .Table {
        .MuiTableCell-root {
            width: 150px;
        }
        .MuiTableHead-root {
            background-color: $light-gray;
            .MuiTableCell-head {
                color: $dark;
            }
            .MuiTableCell-root {
                padding: 22px 16px;
            }
        }
        .CurrencyInput {
            max-width: 150px;
            float: right;
        }
    }
    .message-box {
        border-radius: 10px;
        border: solid 1px $soft-gray;
        background-color: $light-gray;
        white-space: break-spaces;
        padding: 15px 20px;
        flex: 0;
        margin: 10px;
        svg {
            margin: 0 25px 25px 0;
        }
    }
    .SecondaryTabView .view {
        padding: 20px 40px;
    }
}