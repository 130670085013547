@import 'src/theme.scss';

@media (min-width: 0px) {
  .Unauthorized{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    margin: 0px 10%;

    i{
      font-size: 150px;
      color: $secondary_color;
      margin-bottom: 20px;
    }

    h2{
      font-size: 50px;
      margin-bottom: 30px;
      font-weight: 200;
    }

    p{
      font-size: 22px;
      color: $oxford;
      width: 100%;
      max-width: 560px;
      text-align: center;
      margin: auto;
      margin-bottom: 40px;
    }

    button{
      width: 100%;
      height: 50px;
      background-image: linear-gradient(to bottom, $secondary-color, $secondary-color);
      margin-bottom: 40px;
    }

    &__success{
      color: $primary_color;
    }

    &__error{
      color: $pinkish-red;
    }

    &__warning{
      color: $secondary_color;
    }
  }
}

@media (max-width: 900px) {
  .Unauthorized{
    margin: 0px;
    img{
      width: 200px;
    }
    h2{
      font-size: 40px;
    }
    p{
      font-size: 20px;
    }
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .Unauthorized{
    margin: 0px;
    img{
      width: 250px;
    }
    h2{
      font-size: 45px;
    }
    p{
      font-size: 20px;
    }
  }
}