@import 'src/theme.scss';

.SectionCard{
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
  position: relative;

  &__cube{
    background-image: url('../../../../assets/img/rectangle-35.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 159px;
    height: 159px;
    margin: auto;
    cursor: pointer;

    &__containerText {
      color: $orange;
      font-size: 14px;
      margin-left: 3px;
    }

    &__icon{
      margin-bottom: 11px;
    }

    &__item{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &__img{
        height: 110px;
        margin-bottom: 16px;
        margin-top: 18px;
        display: flex;
        justify-content: center;

        img{
          width: 110px;
        }
      }
    }
  }
}
@media(max-width: 900px) {
  .SimpleCard{
    &__cube{
      width: 1000%;
      background-size: contain;
      &__containerIcon{
        img {
          height: 26px;
        }
      }
    }
  }
}
@media(max-width: 780px) {
  .SectionCard {
    width: 33.3%;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    &__cube {
      width: 100%;
      background-size: contain;
      button {
        min-width: 100% !important;
      }
      &__item{
        &__img {
          height: 60px;
          img {
            width: 26px;
          }
        }
      }
    }
  }
  .SectionCard__cube__containerIcon__icon{
    width: 26px;
  }
}
@media(max-width: 600px) {
  .SectionCard {
    padding-right: 0;
    padding-left: 0;
    width: 90%;
    height: auto;
    &__cube {
      width: auto;
      background-size: contain;
      .SimpleCard {
        color: $total-white;
        background-image: linear-gradient(156deg, $light-orange, $orange);
        padding: 10px;
        margin: 10px 0;
        width: auto;
        button {
          background: none !important;
          background-image: none !important;
          box-shadow: none;
          min-width: 120px !important;
        }
      }
      &__item {
        flex-direction: row;
        justify-content: flex-start;
        .MuiButtonBase-root-78 {
          justify-content: flex-start;
          padding: 8px;
        }
        &__img {
          background: $total-white;
          border-radius: 50%;
          padding: 10px;
          height: 35px;
          margin: 0;
          img {
            width: 26px;
          }
        }
      }
    }
  }
}