@import 'src/theme.scss';

.SectionStatus{
  max-width: 350px;
  .MuiButton-root {
    min-width: 0;
  }
  .MuiButton-outlined {
    margin-top: 10px;
  }
}