@import 'src/theme.scss';

.AccountImobly {
    display: flex;
    padding: 10px 25px 70px 52px;
    font-family: $main-font;
    height: auto;
    background-color: $total-white;
    &__description {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        &__date p {
            margin: 12px 0px;
        }
        &__type {
          margin-top: 40px;
           p {
            margin: 12px 0px;
          }
        }
        &__properties{
            margin-top: 40px;
            overflow-y: scroll;
            height: 250px;
            &__prop{
                p {
                text-align: left;
                margin: 10px 0px;
               }
            }

        }

    }
    &__supscripton {
        width: 65%;
        margin-left: 80px;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        padding-left: 50px;
        &__validity{
            p {
                text-align: left;
                margin: 12px 0px;
                font-family: $main-font;
            }
        }
        &__service {
            margin-top: 40px;
            border-bottom: 1px solid #d5d8e5;
            &__buttons{
                margin-bottom: 30px;
                &__button {
                    font-family: $main-font;
                    width: 168px;
                    height: 40px;
                    border-radius: 4px;
                    background-color: $primary-color;
                    color: $total-white;
                    cursor: pointer;
                    margin-bottom: 15px;
                    border: none;
                    font-size: 16px;
                    font-weight: normal !important;
                }
            }
            &__text  {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 25px;
                &__question {
                    p{
                        text-align: justify;
                        padding-right: 100px;
                        font-family: $main-font;
                    }
                }
            }
            .ButtonComponent {
                margin-bottom: 15px;
            }
            p {
                text-align: left;
                margin: 12px 0px;
                font-family: $main-font;
            }
        }
        &__support {
            margin-top: 40px;
            border-bottom: 1px solid #d5d8e5;
            &__text{
                margin-bottom: 40px;
                &__question {
                    margin: 18px 0px;
                    p{
                        text-align: justify;
                        padding-right: 100px;
                        font-family: $main-font;
                    }
                }
                &__button {
                    width: 200px;
                    height: 40px;
                    border-radius: 4px;
                    border: solid 1px $primary-color;
                    font-family: $main-font;
                    background-color: transparent;
                    color: $primary-color;
                    font-size: 18px;
                    cursor: pointer;
                    font-weight: normal !important;
                }
            }

        }
        &__terms {
            margin-top: 30px;
            p {
                a {
                    color: $primary-color;
                    font-family: $main-font;
                }
            }
        }

    }
}

.border {
    border-left: 1px solid $primary_color;
}
.title-account {
    font-family: $main-font !important;
    color: $primary-color !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: 1px !important;
    margin: 10px 0px !important;
}

@media (max-width: 970px) {
    .AccountImobly {
        display: block;
        &__description{
            border-bottom: 1px solid $eggshell-blue;
            &__properties{
                height: 150px;
            }
        }
        &__supscripton{
            width: inherit;
            margin-left: -50px;
            display: flex;
            flex-direction: column;
        }
    }
    .border {
        border-left: none;
    }
  }