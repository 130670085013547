@import 'src/theme.scss';

.Graphic {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 400px;
  box-sizing: border-box;
  &__detail{
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    align-self: center;

    &__img{
      width: 166px;
      height: 119px;
    }
  }
}