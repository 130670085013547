@import "~material-design-icons/iconfont/material-icons.css";
@import 'src/theme.scss';
@import './theme/index.scss';


* {
  margin: 0px;
  padding: 0px;
  font-family: $main-font;
}
 
html {
  scroll-behavior: smooth;
}

#root{
  width: 100%;
  height: 100%;
}

.rc-menu-submenu-popup {
  z-index: 3;
}

.card {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 5px 5px 18px $shadow;
  overflow: auto;
}
