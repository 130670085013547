@import 'src/theme.scss';

.Asset{
  border: 1px solid rgba(0, 160, 154, 0.3);
  margin-top: 20px;
  border-radius: 5px;
  background-color: $light-gray;

  &__header{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    background-color: $dark-green;
    border-radius: 5px 5px 0px 0px;
    &__img{
      padding-top: 4px;
      svg{
        height: 25px;
        width: 60px;
        path{
          stroke: $total-white;
          fill: transparent;
        }
      }
    }

    &__text{
      font-size: 14px;
      color: $total-white;
    }
  }

  &__more{
    padding: 7px 30px;
    color: $secondary_color;
    font-size: 14px;
    label{
      cursor: pointer;
    }

    button{
      color: $secondary-color;
      border: 1px solid $secondary-color;
    }
  }
}

.AssetInfo{
  background-color: $light-gray;
  border: 1px solid rgba(0, 160, 154, 0.3);
  border-radius: 5px;
  width: 100%;
  height: 60px;
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  &__img{
    margin: 0px 26px;
    svg{
      height: 35px;
      max-width: 45px;
      path{
        stroke: $primary_color;
        fill: transparent;
      }
    }
  }

  &__title{
    font-family: $main-font;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    max-width: 665px;
    font-size: 15px;
    color: $primary_color;
  }
}