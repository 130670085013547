@import 'src/theme.scss';

.Info{
	background-color: $light-gray;
	height: 100vh;
	&__header{
		height: 85%;
		width: 100%;
	}
	&__logo{
		position: absolute;
		top: 26px;
		left: 20px;
		z-index: 3;
	}
	&__out{
		position: absolute;
		bottom: 30px;
		right: 26px;
		z-index: 3;
		button {
			color: $oxford;
		}
	}
	&__container{
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 0px;
		left: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		&__title{
			font-size: 35px;
			letter-spacing: 1.5px;
			color: $total-white;
			margin-bottom: 15px;
		}
		&__text{
			text-align: center;
			font-size: 20px;
			letter-spacing: 0.5px;
			color: $total-white;
			font-weight: 200;
			margin-bottom: 20px;
		}
		&__card{
			width: 90%;
			padding: 0px 20px;
			box-sizing: border-box;
			max-width: 900px;
			&__body{
				width: 100%;
				padding: 30px 50px 10px 50px;
				box-sizing: border-box;

				&__text{
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					margin-bottom: 20px;
					&__text{
						font-size: 16px;
						color: $oxford;
						text-align: center;
					}
					&__number{
						height: 20px;
						width: 20px;
						min-width: 20px;
						border-radius: 50%;
						margin-right: 10px;
						display: flex;
						justify-content: center;
						align-items: center;
						color: $total-white;
						font-size: 22px;
						padding: 3px;
						background-color: $primary_color;
					}	
				}
				img{
					width: 100%;
				}
				&__point{
					display: flex;
					flex-direction: row;
					margin: auto;
					width: 100%;
					align-items: center;
					justify-content: center;
					margin-top: 10px;
					&__item{
						border-radius: 50%;
						height: 10px;
						width: 10px;
						background-color: rgba($primary_color, 0.3);
						margin: 10px;
						&__active{
							background-color: $primary_color;
						}
					}
				}
			}
		}
		&__options{
			width: 90%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin: 20px auto;
		}
		&__options2{
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			margin: 0px auto;
		}
	}
}

@media (max-width: 900px) {
	.Info{
		&__header{
			display: none;
		}
		&__out{
			top: 30px;
			right: 20px;
			button {
				color: $total-white;
			}
		}
		&__headerMob{
			width: 100%;
			height: 280px;
			background: $dark-green;
		}
		&__container{
			&__title{
				padding: 0px 20px;
				font-size: 18px;
			}
			&__text{
				padding: 0px 10px;
				font-size: 16px;
			}
			&__card{
				padding: 0px 10px;
				&__body{
					padding: 20px 10px;
					&__text{
						&__text{
							font-size: 14px;
						}
						&__number{
							font-size: 14px;
						}	
					}
					&__point{
						margin-top: 5px;
						&__item{
							margin: 0px 10px;
						}
					}
				}
			}
			&__options{
				margin: 10px auto;
				padding: 0px 10px;
				flex-direction: row;
				justify-content: space-between;
				&.next {
					flex-direction: row-reverse;
				}
			}
		}
	}
}
.next_tuto {
	background-color: $secondary-color !important ;
	color: $total-white !important;
	margin: 0px 10px !important;
	padding: 5px 18px !important ;
}

.before_tuto{
	border-radius: 4px !important;
	border: solid 1px  $secondary-color !important;
	padding: 5px 18px !important ;
	margin: 0px 10px !important;
}

@media (max-width: 900px) {
	.Info{
		&__logo{
			left: 20px;
		}
		&__out{
			bottom: 5px;
			right: 10px;
		}
		&__container {
			height: auto;
			top: 120px;
			&__card {
				margin-top: 20px;
			}
		}
	}
}

