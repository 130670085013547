@import 'src/theme.scss';

.ReportModal{
  &__title{
    font-size: 22px;
    font-weight: 200;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid $soft-gray;
    text-align: center;
    align-items: center;

  }

  .body{  
    align-items: center;
    font-size: 14px;
    font-family: $main-font;
    color: $primary-color;
    font-weight: 200;
    margin-bottom: 15px;
    text-align: center;

    a{
      color: $secondary_color;
      cursor: pointer;
      text-decoration: none;
    }
    .form {
      align-items: center;
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;
      div[role="progressbar"] {
        margin: 10px auto;
        display: table;
      }
      &, .column {
        flex-direction: column;
        display: flex;
        & > label, .RadioGroup legend {
          color: $oxford;
          font-size: 15px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 10px;
          margin-top: 10px;
          align-items: center;
        }
        .input_modal{
          text-align: left;
          align-items: left;
          margin-top: 24px;
        }
        .text_modal{
          text-align: center;
          align-items: center;
          padding-top: 18px;
          font-family: $main-font;
          font-size: 14px;
          color: gray;
        }
        .div-button{
          text-align: center;

          button {
            background-image: linear-gradient(to bottom, $secondary-color, $secondary-color);
          }
        }
        .button_modal{
          
          width: 150px;
          height: 40px;
        }
        .pay_method {
          label {
            span:nth-child(2) {
              font-size: 18px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $oxford;
            }
          }
        }
        .expiration-date {
          & > div {
            label {
              display: none;
              & + div {
                margin-top: 0;
              }
            }
            &:nth-child(1) {
              width: calc(60% - 20px);
              margin-right: 20px;
            }
            &:nth-child(2) {
              width: 40%;
            }
            & > div > div {
              border-color: $oxford !important;
              &, & > div {
                height: 35px;
                padding: 0;
                width: 100%;
                color: $oxford;
                font-size: 18px;
                &:focus {
                  background: transparent;
                }
              }
              & > div {
                padding: 7px 16px;
                height: auto;
              }
              svg {
                border-color: $oxford;
              }
            }
          }
        }
      }
    }
  }
  &__options{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
  }

}

@media (max-width: 900px){
  .SimpleModal-paper-43{
    width: 80% !important;
    height: 36%; 
  }
}
