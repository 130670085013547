@import "src/theme.scss";

.ConfigProfile {
  padding: 10px 25px 70px 52px;
  font-family: $main-font;
  height: auto;
  background-color: $total-white;
  &__displayName {
    display: flex;
    padding: 10px 0px;
    flex-direction: row;
    &__img {
      width: 100px;
      height: 100px;
    }
    &__description {
      width: 20%;
      display: flex;
      flex-direction: column;
      margin-left: 30px;
      margin-top: 20px;
    }
  }
  &__dataProfile {
    display: flex;
    height: auto;
    margin: 60px 10px;
    &__personal {
      width: 30%;
      &__subtitle {
        color: $primary_color !important;
        &__text {
          font-family: $main-font;
          font-weight: 400;
          font-size: 16px;
          color: $dark;
        }
      }
      &__button {
        font-family: $main-font;
        width: 219px;
        height: 40px;
        border-radius: 4px !important;
        border: solid 1px $primary-color !important;
        color: $primary-color;
        font-size: 16px;
        cursor: pointer;
      }
    }
    &__information {
      border-left: 1px solid $primary-color;
      padding-left: 40px;
      &__text {
        font-family: $main-font;
        font-weight: 400;
        font-size: 16px;
        color: $dark;
      }
    }
  }
  .heading-2{
    margin-bottom: 0px !important;
  }
  .subtitle {
    color: $secondary-color !important;
  }
}

@media (max-width: 970px) {
  .ConfigProfile { 
    &__dataProfile {
        display: flow-root;
        &__personal {
            width: auto;
        }
        &__information{ 
            border-left: none;
            padding-top: 20px;
            padding-left: 0px;
        }
    }
  }
}
