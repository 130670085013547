@import 'src/theme.scss';

.ListAmenities {
  overflow: auto;
  max-height: 730px;
  min-width: 200px;
  margin-right: 10px!important;

  &__Item {
    border-radius: 10px;

    &:hover {
      background-color: $soft-gray !important;
      border-radius: 10px!important;

      .MuiTypography-body1-10 {
        color: $dark !important;
      }
    }

    &__active {
      background-color: $dark-green!important;
      color: $white!important;
      border-radius: 10px!important;

      .MuiTypography-body1-10 {
        color: $white!important;
      }
    }
  }

}
