@import 'src/theme.scss';

.Table{
    width: 100%;
    padding-bottom: 20px;
    &__header{
      display: flex;
      flex-direction: row;
      &__section1{
        padding: 8px;
        background-color: $secondary-color;
        border-top-left-radius: 12px;
        width: 27.1%;
        * {
          color: $total-white;
        }
      }
      &__section2{
        padding: 8px;
        flex: 1;
        background-color: $primary-color;
        border-top-right-radius: 12px;
        * {
          color: $total-white;
        }
      }
    }
    &__headers{
      flex: 1;
      font-size: 14px;
      text-align: center;
      color: $primary-text;
      display: flex;
      background-color: $eggshell-blue;
      padding: 3px;
      div{
        width: 14.28%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__body{
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
    }
    &__row{
      font-size: 14px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $gray-3;
      transition-duration: 0.5s;
      div {
        width: 14.28%;
        display: flex;
        flex-direction: row;
      }
      button {
        padding: 0px;
      }
      &__selected{
        background-color: rgba($primary_color, 0.2);
      }
    }
    &__rowOn{
      background-color: aquamarine;
      transition-duration: 0.5s;
    }
    &__input{
      height: 30px;
      width: 98%;
      text-align: center;
      background-color: transparent;
      border: none;
      font-size: 14px;
        &__error{
            background-color: rgba(red, 0.1);
            text-align: center;
            border: none;
            width: 100%;
            color: red;
            font-size: 14px;
            height: 30px;
        }
    }
    &__popover{
      position: relative;
      padding-right: 40px;
      box-sizing: border-box;
      span{
          cursor: pointer;
          position: absolute;
          right: 15px;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
      }
  }
}
