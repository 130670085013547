@import 'src/theme.scss';

@media (min-width: 0px) {
  .Login{
    &__signup{
      &__text{
        span {
          color: $oxford;
          font-size: 15px;
        }
      }
    }
  }
  .SignUp{
    padding: 0px 20px;
    box-sizing: border-box;
    max-width: 560px;
    margin: auto;

    &__title{
      margin-bottom: 20px;
      text-align: center;
      font-size: 40px;
      color: $primary_color;
    }

    &__social{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;

      &__facebook{
        margin: 0px;
        &__button{
          background-color: $facebook;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: $total-white;
          width: 100%;
          height: 40px;
          border-radius: 20px;
          cursor: pointer;
          svg {
            font-size: 30px;
          }

          &__containerLogo{
            display: flex;
            flex-direction: row;
            justify-content: 'center';

            &__logo {
              margin-right: 10px;
            }

            span{
              font-size: 15px;
              margin-top: 4px;
            }
          }
        }
      }

      &__google{
        margin-bottom: 20px;
        &__button{
          background-color: $total-white;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: $total-white;
          width: 100%;
          height: 40px;
          border: 1px solid $primary-color-ultra;
          border-radius: 20px;
          cursor: pointer;
          svg {
            font-size: 40px;
          }

          &__logo{
            margin-right: 10px;
          }

          span {
            color: $dark;
            font-size: 15px;
            line-height: 40px;
          }
        }
      }
    }

    &__options{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 17px;

      &__span {
        border-bottom: 1px solid gray;
        width: 20px;
        height: 3px;
        margin: 0px 10px;
      }

      &__divider{
        background-color: $soft-gray;
        height: 1px;
        width: 100%;
        margin-top: 15px;
      }

      span{
        color: $oxford;
        font-size: 15px;
      }
    }

    &__input{
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
    }

    &__button{

      padding: 10px;
      *{
        color: $total-white;
      }
      
      button{
        width: 100%;
        height: 50px;
        object-fit: contain;
        background-image: linear-gradient(to bottom, $secondary-color, $secondary-color);
        margin:60px 0 25px 0;
        font-size: 1.3em;
        font-weight: 500;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }
  }
}