@import 'src/theme.scss';

.PanelInfo{
  background-color: aqua;
  height: 100vh;
  &__header{
    height: 120px;
    display: flex;
    flex-direction: row;
    background-color: aquamarine;
    &__logo{
      width: 606px;
      background-color: $dark-green;
    }
    &__control{
      flex: 1;
      background-color: blueviolet;
    }
  }
}