@import 'src/theme.scss';

.BudgetEdit{
  padding: 20px;

  &__tabs{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__goback{
    position: absolute;
    left: 0px;
    top: 0px;
    color: $secondary_color;
    display: flex;
    align-items: center;
    cursor: pointer;
    label {
      cursor: pointer;
    } 
  }

  &__title{
    font-size: 18px;
    margin-bottom: 20px;
  }
}

@media(max-width: 900px){
  .BudgetEdit{
    padding: 20px 0px;
    &__goback{
      label{
        display: none;
      }
    }
  }
}