@import 'src/theme.scss';

.Form {
  .heading-5 {
    margin-bottom: 20px;
  }
  .row + .row {
    margin-top: 20px;
  }
  .row.spacing .col {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .MuiFormControl-root {
    flex: 1;
  }
  .align-left {
    &, & * {
      text-align: left;
    }
  }
  .read-only {
    .MuiInput-underline {
      &::before, &::after {
        content: initial !important;
      }
    }
  }
  .MuiButton-root + .MuiButton-root {
      margin-left: 20px;
  }
  .MuiFormLabel-root {
    text-transform: uppercase;
    color: $secondary-color !important;
  }
  .FieldConcept, .SelectMenu {
    width: 100%;
  }
}