@import 'src/theme.scss';

.ModalInvitations{
  &__title{
    font-family: $main-font;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: $dark;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid $soft-gray;
  }
  &__body{
    font-size: 18px;
    color: $dark;
    font-weight: 200;
    text-align: center;
    margin-bottom: 30px;
    a{
      color: $secondary_color;
      cursor: pointer;
      text-decoration: none;
    }
  }
  &__options{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 900px){
  .SimpleModal-paper-43{
    width: 80% !important;
    height: 36%; 
  }
}
