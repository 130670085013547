@import 'src/theme.scss';

.BudgetConcept{

  &__header{
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    background-color: rgba($primary_color, 0.2);
    &__title{
      width: 20%;
      text-align: center;
      font-size: 14px;
      color: $oxford;
      &.col-3 {
        width: 30%;
      }
    }
    &__remember{
      width: 12%;
      text-align: center;
      font-size: 14px;
      color: $oxford;
    }
    &__delete{
      width: 8%;
      text-align: center;
      font-size: 14px;
      color: $oxford;
    }
  }
}

.BudgetConceptInfo{
  font-family: $main-font;
  width: auto;
  color: $dark-green;
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-right: 26px;
}

@media(max-width: 900px) {
  .BudgetConceptInfo{
    width: auto;
    margin-right: 26px;
  }
}