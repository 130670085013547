
.PropertiesConfiguration{
  display: flex;
  justify-content: center;
  align-content: center;

  &__container{
    width: 100%;
    max-width: 1000px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column; 
  }
}