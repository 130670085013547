@import 'src/theme.scss';

.BudgetTab{
  display: flex;
  flex-direction: row;
  background-color: $total-white;
  border-bottom: 1px solid $gray;
  overflow-x: auto;
  width: 100%;
}

.BudgetTabSection{
  &:not(:first-child) {
    border-left: 1px solid $gray;
  }
  &__title{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &__title{
      min-width: 116px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      background-color: $gray-3;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
    }
  }
  &__tabs{
    margin: 7px 10px 0px 20px;
    display: flex;
    flex-direction: row;
    max-width: calc(100vw - 117px);
    overflow: auto;
  }
}