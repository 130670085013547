@import 'src/theme.scss';

.WizardHeader {
  .justify { 
    text-align: justify;
  }
  .img-properties {
    min-width: 60px;
    svg {
      path {
        stroke: transparent;
        fill: $secondary-color;
      }
    }
    img{
      height: 100%;
    }
  }
  form { 
    max-width: 180px;
  }
  button {
    max-width: 180px;
  }
  .body-text1 { 
    min-width: 90px;
  }
  .space-right { 
    span { 
        margin-right: 5px;
    }
    svg {
      height: 20px !important;
      cursor: pointer;
    }
  }
  .color-primary { 
    color: $primary-color !important;
  }
  .bottom-space {
    margin-bottom: auto !important;
  }
}

