@import 'src/theme.scss';

.Select {

    .MuiSelect-root:not(.MuiSelect-outlined) {
        padding: 6px 10px !important;
        height: auto !important;
        background-color: $white !important;
        border: 1px solid $soft-gray !important;
        border-radius: 5px !important;
        &:before, &:after { 
            border: none !important;
        }
    }
    .MuiSelect-icon:not(.MuiSelect-iconOutlined) {
        color: $gray-4 !important;
        border-left: 1px solid $gray-3 !important;
        top: -1 !important;
        width: 24px;
        height: 24px;
    }
}