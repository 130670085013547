@import "src/theme.scss";

$nav_height: 60px;

.PanelDashboard {
  display: flex;
  flex-direction: row;
  height: 100vh;
  &__menu {
    display: flex;
    flex-direction: row;
    transition-duration: 0.5s;
    position: absolute;
    &__simple {
      width: 70px;
      z-index: 3;
      &__nav {
        height: $nav_height;
        cursor: pointer;
        padding: 0px 12px;
        img {
          height: $nav_height;
          width: 50px;
        }
      }
    }

    &__complete {
      width: 180px;
      height: 100%;
      position: absolute;
      left: -180px;
      transition-duration: 0.5s;
      opacity: 0;
      z-index: 3;
      &__show {
        left: 70px !important;
        transition-duration: 0.5s;
        opacity: 1;
      }
      &__nav {
        height: $nav_height;
        display: flex;
        justify-content: left;
        align-items: center;
        &__none {
          display: none;
        }
      }
    }
  }
  &__menu:hover {
    width: 250px;
  }
  &__container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
    &__nav {
      height: $nav_height;
      &__none {
        display: flex;
      }
    }
    &__body {
      scroll-behavior: smooth;
      overflow-y: auto;
      padding-left: 70px;
      background-color: $light-gray;
      height: calc(100vh - #{$nav_height});
      display: flex;
      flex: 1;
    }
  }
}

@media (max-width: 1200px) {
  .PanelDashboard {
    width: 100%;

    &__menu {
      background-image: $total-white;
      display: flex;
      flex-direction: column;
      &__background {
        position: absolute;
        height: 100vh;

        background-color: $total-white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition-duration: 0.5s;
        left: -180px;
        z-index: 2;

        &__active {
          transition-duration: 0.5s;
          left: 0px;
          width: 200px;
        }
      }

      &__simple {
        &__nav {
          height: $nav_height;
          box-sizing: border-box;
          padding: 12px;
          img {
            width: 50px;
          }
        }
      }

      &__complete {
        width: 180px;
        height: 100%;
        position: absolute;
        left: -180px;
        transition-duration: 0.5s;
        opacity: 0;
        z-index: 3;
        &__show {
          left: 10px !important;
          transition-duration: 0.5s;
          opacity: 1;
          position: absolute;
        }
        &__nav {
          height: $nav_height;
          display: flex;
          justify-content: left;
          align-items: center;
          &__none {
            display: flex;
            padding-top: 20px;
            padding-left: 10px;
          }
        }
      }
    }
    &__container {
      &__nav {
        display: none;
        &__none {
          display: none;
        }
      }
      &__body {
        overflow-y: auto;
        background-color: $light-gray;
        height: calc(100vh - #{$nav_height});
      }
    }
  }
}