@import 'src/theme.scss';

.Form.deprecated {
  min-height: 420px;
  &__text{
    background-color: $primary_color;
    padding: 20px;
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    * {
      color: $total-white;
    }
  }
  &__form{
    padding: 30px;
  }
  &__separador{
      margin-bottom: 20px !important;
  }
  &__row{
      display: flex;
      flex-direction: row;
      padding: 0px;
  }
  &__colum{
      width: 70%;
  }
  &__focus{
    transition-duration: 0.5s;
  }
  &__focusOn{
    background-color: aquamarine;
    position: relative;
    padding: 20px 10px 0px 10px;
    border-radius: 8px;
    transition-duration: 0.5s;
  }
}