@import 'src/theme.scss';

.BudgetDetail {
  &__content{
    &__options {
      width: 100%;
      margin-top: 0px !important;
      button {
        background-image: linear-gradient(to bottom, $secondary-color, $secondary-color);
      }
    }

    &__cost{
      padding-bottom: 15px;
      font-size: 12px;
      color: $primary-color;
      margin-top: 6px;
      display: flex;
      width: 100%;
      align-items: center;
      &__detail{
        width: 20%;
      }
    }

    &__label{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;

      div{
        background-color: $light-orange;
        width: 850px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 14px;

        span{
          font-weight: 500;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 10px;

          &:before{
            content: '?';
            display: inline-block;
            border: 1px solid black;
            border-radius: 5px;
            height: 20px;
            width: 20px;
            margin-right: 10px;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    &__header{
      &__resume{
        &__balance{
          max-width: 140px;
          align-items: center;
          color: $dark;
          padding: 0px 5px;
          margin-top: 13px;
          p {
            font-size: 14px;
            font-family: $main-font;
            margin-bottom: 5px;
            font-weight: 400;
            color: $dark;
          }
          label{
            color: $oxford;
          }
          input{
            height: 30px;
            width: 100%;
            text-align: center;
            border: none;
            padding-bottom: 10px;
            box-sizing: border-box;
            outline: none;
            font-size: 18px;
            &:after{
              content: '$';
              display: block;
              color: $dark;
              width: 10px;
              height: 5px;
            }
          }
        }
      }
    }
  }
  .header-detail {
    justify-content: space-between !important;
    margin: 20px 9px;
    .header-detail-flex {
      flex: 1;
    }
  }
}
.space-between { 
  justify-content: space-between;
}
@media (max-width: 900px){
  .BudgetDetail {
    &__content{
      &__label{
        div{
          height: auto;
        }
      }
      &__header{
        flex-direction: column;
        &__balance{
          flex: 1;
          max-width: 900px;
          width: 100%;
          justify-content: center;
          align-items: center;
          padding: 5px 0px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .BudgetDetail {
    &__content{
      &__label{
        div{
          height: auto;
        }
      }
      &__header{
        flex-direction: column;
        align-items: center;
        &__balance{
          flex: 1;
          max-width: 900px;
          width: 100%;
          justify-content: center;
          align-items: center;
          padding: 5px 0px;
        }
      }
    }
  }

  .BudgetDetail__content__header__balance p {
    bottom: 50px;
  }

}
.BudgetDetail__content__header__balance_p {
  font-size: 12px !important;
  margin-left: -20px;
}

@media (max-width: 1200px){
  .BudgetDetail__content__header__balance  {
    max-width: 125px;
  }
  .Header__tab{
    margin: 0px;
  }
}
@media (max-width: 480px){
  .BudgetDetail__content__header__balance {
    max-width: 100%;
    padding: 20px 0;
    .BudgetDetail__content__header__balance_p {
      margin-bottom: 10px;
    }
    .MuiFormControl-root-93 {
      width: 100%;
    }
  }
  .BudgetDetail__content__header__resume {
    width: 100%;
    .Header {
      padding: 10px;
      .Header__tabs {
        .Header__found {
          margin: 10px auto;
        }
        .Header__found_div {
          flex-wrap: wrap;
          .Header__tab {
            flex: 50%;
          }
        }
      }
    }
  }
}
.BudgetDetail__content__cost{
  padding-bottom: 15px;
  font-size: 12px;
  color: $primary-color;
  margin-top: 6px;
  display: flex;
  width: 100%;
}
.BudgetDetail__titles__cost{
  font-size: 11px;
    color: $primary-color;
    text-align: center;
    margin-top: 6px;
    align-items: center;
}
.BudgetDetail__titles__cost__detail{
  width: 20%;
}
