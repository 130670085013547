@import 'src/theme.scss';

.TableAccordion{
    width: 100%;
    &__header{
      display: flex;
      flex-direction: row;
      &__section1{
        width: 28.56%;
        padding: 5px;
        background-color: $secondary-color;
        * {
          color: $total-white;
        }
      }
      &__section2{
        width: 71.4%;
        padding: 5px;
        background-color: $primary-color;
        * {
          color: $total-white;
        }
      }
    }
    &__headers{
      flex: 1;
      font-size: 12px;
      text-align: center;
      color: $primary-text;
      display: flex;
      background-color: $eggshell-blue;
      padding: 3px;
      div{
        width: 14.28%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__body{
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    &__row{
        font-size: 14px;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $gray-3;
        transition-duration: 0.3s;
        div {
          width: 14.28%;
          display: flex;
          flex-direction: row;
        }
        button {
          padding: 0px;
        }
        &__selected{
          background-color: rgba($primary_color, 0.2);
          transition-duration: 0.3s;
        }
    }
    &__input{
        background-color: transparent;
        border: none;
        width: 100%;
        height: 30px;
        font-size: 14px;
        text-align: center;
        outline: none;
        &__error{
            background-color: rgba(red, 0.1);
            border: none;
            width: 100%;
            color: red;
        }
    }
    &__input::placeholder{
        font-style: italic;
        color: $gray
    }
    &__center{
        color: $gray;
    }
    &__popover{
        span{
            background-color: $secondary-color;
            color: $total-white;
            width: 23px;
            height: 23px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 7px;
        }
    }
    &__show{
      background-color: aquamarine;
      transition-duration: 0.3s;
    }
}

.one { 
  color: $secondary-color !important;
}