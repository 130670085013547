@import 'src/theme.scss';

.RequestsList {
    .scroll-list {
        max-height: calc(100vh - 406px);
        overflow: auto;
        min-height: 212px;
    }
    .head {
        justify-content: space-between;
        align-items: baseline;
        h4 { 
            margin-right: 10px;
        }
    }
    .bookings-not-found { 
        margin-top: 10px;
        img { 
            margin-bottom: 21px;
        }
        h5 {
            text-align: center;
        }
    }
    .select { 
        top: -3px;
    }
}