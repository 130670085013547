@import 'src/theme.scss';

.PropertiesConfigurationItem{
  border-bottom: 1px solid $light-gray;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  &__image{
    width: 100px;
    height: 100px;
    border: 1px solid $primary-color;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    svg {
      height: 90px;
      max-width: 90px;

      path{
        stroke: transparent;
        fill: $dark-green;
      }
    }
  }
  &__name{
    font-size: 20px;
    flex: 1;
    justify-content: flex-start;
    padding: 0px 20px;
    box-sizing: border-box;
  }
  &__options{
    button{
      min-width: 150px;
      margin-left: 10px;
    }
  }
}

@media(max-width: 900px) {
  .PropertiesConfigurationItem{
    flex-direction: column;
    &__name{
      padding: 15px 20px;
    }
    &__options{
      display: flex;
    }
  }
}