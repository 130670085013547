@import 'src/theme.scss';

.BuildListItem{
	&__container {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		&__condominiums {
			display: flex;
			flex-direction: row;
			margin-top: 5px;
			&__svg {
				margin-right: 20px;
			}
			&__dptos {
				text-align: initial;
			}
		}
		&__options { 
			display: flex;
			flex-direction: row;
			align-items: center;
			&__icon{
				margin: 3px 8px;
				cursor: pointer;
			}
		}
	}

	padding: 8px 12px;
	&:not(:first-child) {
		border-top: 1px solid $border-color;
	}

    &__img{
      svg{
        width: 40px;
        max-height: 40px;
        path{
			stroke: transparent;
			fill: $primary-color;
        }
      }
    }
    &__item{
      display: flex;
			flex-direction: column;
			flex: 1;
      &__info{
				padding-left: 15px;
				display: flex;
				flex-direction: row;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				&__name{
					padding-right: 10px;
					color: $primary-color;
					font-size: 17px;
				}
				&__number{
					color: $oxford;
					font-size: 17px;
					display: flex;
					align-items: center;		
				}
			}
			&__access{
				display: flex;
				flex-direction: row;
				padding-left: 15px;
				flex-wrap: wrap;
				max-width: 480px;
				padding-top: 5px;
			}
		}
		&__opc{
			button {
				width: 40px;
				height: 40px;
				padding: 0px;
				background-color: rgba($secondary_color, 0.1);
			}
			&__icon{
				svg{
					height: 15px;
				}
			}
		}
		
		&__access{
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-right: 20px;
			font-size: 12px;
			color: $oxford;
			margin-top: 2px;
			svg {
				padding-right: 10px;
				width: 40px;
				max-height: 40px;
			}
		}
		&__accessAdd{
			color: $secondary_color;
			font-size: 16px;
			cursor: pointer;
			img{
				width: 20px;
				margin-right: 5px;
				position: relative;
				top: 5px;
			}
		}
		&__accessAdd2{
			display: flex;
			justify-content: center;
			align-items: center;
			color: $secondary_color;
			font-size: 12px;
			cursor: pointer;
			img{
				width: 18px;
				margin-right: 5px;
				position: relative;
			}
		}
		&__accessAddIcon{
			width: 20px;
			margin-left: 10px;
			cursor: pointer;
		}
	}
	
@media(max-width: 1000px) {
	.BuildListItem{
		&.hidden {
			display: none;
		}
		&__selected{
			.BuildListItem__opc {
				display: none;
			}
			.BuildListItem__item__access, .BuildListItem__accessAdd2 {
				display: none;
			}
		}
	}
}

.body-text1{
	margin-bottom: 0px !important;
}