
.BudgetPendingModal{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__title{
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    margin-bottom: 10px;
  }
  &__error{
    color: #c20808;
  }
  &__success{
    color: #00a09a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__text{
    font-size: 18px;
    color: #5e6369;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    margin-bottom: 40px;
  }
  &__options{
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media (max-width: 900px){
  .ModalPendingDiv{
    width: 280px;
  }
}
.BudgetPendingModal__text__cash{
  color: #00a09a;
}
.BudgetPendingModal__text__cash__p{
  display: flex;
  justify-content: space-around;
}

.BudgetPendingModal__text__cash__centrar{
  width: 40%;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 5px;
}
.BudgetPendingModal__text__cash__centrar_items{
  width: 20%;
  text-align: left;
  margin-bottom: 5px;
}

