@import 'src/theme.scss';

.Report{
  display: contents;
  justify-content: center;
  align-items: center;

  &__container{
    width: 100%;
    padding: 0px 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    h2{
      margin-top: 20px;
    }

    &__box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
}
