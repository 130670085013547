@import 'src/theme.scss';

.PanelWizard{
  height: 100vh;
  padding-top: 60px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  &__header{
    height: 60px;
    background-color: $total-white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    &__logo{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      margin-left: 40px;
    }
    &__steper{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__close{
      padding: 15px 30px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      display: flex;
      h6 {
        white-space: nowrap;
      }
      * {
        color: $gray;
      }
    }
  }
  &__body{
    flex: 1;
    background-color: $light-gray;
    overflow-y: auto;
  }
}
@media(max-width: 1235px) {
  .PanelWizard__header
  {
    &__header {
      &__logo {
        margin-left: 0px;
      }
    }

    background-color: $total-white;
    &__steper {
      .StepperWizard {
        &Item {
          display: none;
          &.active {
            .MuiTypography-body2-9 {
              font-size: 20px;
            }
          }
          &__number__active {
            border: none;
            span {
              border: none;
              color: $dark-green;
            }
          }
          &__line {
            display: none;
          }
        }
      }
    }
  }
}

@media(max-width: 900px) {
  .PanelWizard__header {
    &__header {
      &__logo {
        margin-left: 0px;
      }
    }

    background-color: $total-white;
    .PanelWizard__header__steper {
      .StepperWizardItem {
        display: none;
      }
    }
  }
}

@media(max-width: 600px) {
  .PanelWizard__header {
    &__header {
      &__logo {
        margin-left: 0px;
      }
    }

    &__logo{
      img:nth-child(1){
        height: 40px;
      }
    }
    &__close {
      padding: 0;
      padding-right: 10px;
    }
  }
}
