@import 'src/theme.scss';

.ant-btn-primary {
    background-color: $secondary-color !important;
    border: none !important;
}

.MuiButton-root {
    font-weight: normal !important;
}

.MuiButton-outlinedPrimary:not(.Mui-disabled) {
    border-color: $secondary-color !important;
    color: $secondary-color !important;
}

.MuiButton-containedPrimary:not(.Mui-disabled) {
    background-color: $secondary-color !important;
}

.MuiButton-label { 
    white-space: nowrap;
}
