@import 'src/theme.scss';

.RelationsAmenityItem{
  padding: 3px 3px 5px 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span{
    font-size: 24px;
    width: 35px;
    color: $primary_color;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 170px;
    height: 100px;
    
    svg{
      margin-top: 6px;
      margin-bottom: 4px;
      height: 28px;
      max-width: 28px;
      path{
        stroke: $primary_color;
        fill: transparent;
      }
    }

  }

  &__title{
    font-size: 14px;
    color: $secondary-color;
    margin-bottom: 2px;
  }

  &__unit{
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: $light-gray;

    &__label{
      color: $dark;
      width: 55%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      padding: 3px 5px 3px 0px;
    }
    &__number{
      width: 45%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      padding: 3px 0px 3px 5px;
      font-weight: 500;
    }
  }
  &__unit2{
    border-top: 1px solid rgba(227, 227, 227, 0.55);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}