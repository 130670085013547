@import 'src/theme.scss';

.AddHomePage{
    padding-top: 30px;
    &__label{
        text-align: center;
        margin-bottom: 30px;
        margin-top: 50px;
        padding: 0 20px;
    }
    &__body{
        padding: 0px 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: auto;
        max-width: 1300px;
        .Table__body {
            max-height: calc(100vh - 455px);
        }
        &__add{
            width: 330px;
            padding: 0px 0px 20px 0;
            .Form {
                min-height: initial;
            }
            .SimpleCard {
                width: 250px;
                height: auto;
            }
        }
        &__pane{
            height: 100%;
            width: calc(100% - 270px);
            padding-right: 10px;
            justify-content: center;
            align-items: center;
            text-align: center;
            &__content{
                position: relative;
                overflow: auto;
                border-radius: 12px 12px 0 0;
            }
        }
    }
    &__options{
        width: 100%;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0px;
        button {
            background-color: $secondary-color !important;
        }
    }
    &__add{
        position: absolute;
        right: 15px;
    }
}

// *********************   CLASES ADD-HOME-PAGE ********************** //




.AddHomeOptions{
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    &__field{
        width: 100%;
        max-width: 253px;
        &__margin{
            width: 100%;
            max-width: 253px;
            margin-bottom: 20px !important;
        }
    }
}


@media(max-width: 1100px) {
    .Form__focusOn {
        padding: 0;
        border-radius: 0;
    }
    .AddHome {
        &Form{
            &__form {
                height: auto;
                & > .MuiFormControl-fullWidth-95, & > .Form__focus {
                    width: auto;
                    display: inline-flex;
                    margin-right: 20px;
                    margin-bottom: 0 !important;
                    &:first-child {
                        min-width: calc(90vw - 530px);
                    }
                    label {
                        position: relative;
                        white-space: nowrap
                    }
                    .AddHomePageForm__separador {
                        margin-bottom: 0 !important;                    
                    }
                }
            }
        }
        &Page {
            &__body {
                flex-direction: column;
                &__add, &__pane {
                    .SimpleCard {
                        width: 100% !important;
                        height: auto !important;
                    }
                    width: 100% !important;
                }
            }
        }
    }
}
@media(max-width: 790px) {
    .AddHome {
        &Form{
            &__form {
                height: auto;
                & > .MuiFormControl-fullWidth-94, & > .Form__focus {
                    width: 100%;
                    display: flex;
                    margin-right: 0;
                    &:not(:last-child) {
                        margin-bottom: 30px !important;
                    }
                    .AddHomeForm__separador {
                        margin-bottom: 20px !important;                    
                    }
                }
            }
        }
    }
}