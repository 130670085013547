@import 'src/theme.scss';

.AddTowerForm{
  &__text{
    background-color: $primary_color;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $total-white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &__form{
    padding: 20px;
  }
  &__separador{
    margin-bottom: 20px !important;
  }
}

.Button_add{
  width: 100%;
}