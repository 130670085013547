@import 'src/theme.scss';

.QuotaHeader{
  display: flex;
  flex-direction: row;
  background-color: $dark-green;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid $dark-green;
  border-bottom: none;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  &__item{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    &__number{
      width: 15%;
      font-size: 14px;
      border-right: 1px solid rgba($primary_color, 0.5);
      color: $total-white;
    }
    &__name{
      width: 35%;
      font-size: 14px;
      border-right: 1px solid rgba($primary_color, 0.5);
      color: $total-white;
    }
    &__balance{
      width: 15%;
      font-size: 14px;
      border-right: 1px solid rgba($primary_color, 0.5);
      color: $total-white;
    } 
    &__interest{
      width: 15%;
      font-size: 14px;
      border-right: 1px solid rgba($primary_color, 0.5);
      color: $total-white;
    } 
    &__fee{
      width: 20%;
      font-size: 14px;
      position: relative;
      color: $total-white;
      &__options{
        position: absolute;
        right: 0px;
        color: $secondary_color;
        margin-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.Header_checkbox{
  color: $total-white !important;
}