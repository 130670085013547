@import 'src/theme.scss';
.field-date {
    height: 30px;
    border: 1px solid $soft-gray;
    background-color: $total-white;
    border-radius: 5px;
    padding: 0px 10px;
    box-sizing: border-box;
    color: $dark;
    cursor: pointer; 
    .MuiInput-underline:before {
        border-bottom: none !important;
    }
}

#date-nextPayment {
    padding-bottom: 4px !important;
    text-align: right !important;
    font-family: $main-font;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: normal;
}
