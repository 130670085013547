@import 'src/theme.scss';

.NeighborPanel {
  box-sizing: border-box;
  margin-bottom: 20px;
  .heading-2 {
    white-space: nowrap;
    font-weight: 400;
  }
  .name {
    color: $dark !important;
  }
  .ColCard .sections div label {
    text-align: left;
    margin: 0;
  }
  .subtitle {
    white-space: nowrap;
  }
  button {
    color: $secondary_color;
    font-size: 13px;
    margin-left: -8px;
    margin-bottom: -8px;
    min-width: 0;
    justify-content: flex-start;
    align-self: flex-start;
  }
  img {
    margin-right: 15px;
    height: 30px;
    max-width: 30px;
  }
}