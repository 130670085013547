@import 'src/theme.scss';

.ModalTransferSuccess { 
    &__title {
        padding-bottom: 17px;
        border-bottom: 1px solid $soft-gray;
        text-align: center;
    }
    &__circle {
        margin: 40px 0px;
        svg{
            height: 66px;
            width: 64px;
            transition-duration: 0.3s;
            margin: auto;
            display: flex;
          }
    }
    &__transf{
        text-align: center;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    &__button-paymen { 
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
        &__botton-save{
            margin: 15px 15px;
        }
    }
}