@import 'src/theme.scss';

.PaymentModal {
    form {
        padding: 0 70px;
        label {
            text-align: center;
        }
        .container-data {
            padding: 24px 90px;
            .text-green, .MuiFormLabel-root {
                color: $secondary-color;
            }
            .spacing-section{
                margin: 15px 0;
            }
        }
    }
    p {
        margin-bottom: 5px;
    }
    .disclaimer {
        color: $oxford !important;
    }
    .icon {
        margin: 30px 0;
    }
}