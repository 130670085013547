@import 'src/theme.scss';

@media (min-width: 0px) {
  .Code{
    padding: 0px 20px;
    box-sizing: border-box;
    max-width: 560px;
    margin: auto;

    &__title{
      font-size: 50px;
      text-align: center;
      color: $primary_color;
      margin-bottom: 30px;
    }

    &__text{
      font-size: 20px;
      text-align: center;
      color: $oxford;
      margin-bottom: 50px;
    }

    &__email{
      margin-top: 10px;
    }

    &__input{
      margin-top: 64px;
    }

    &__button{
      button{
        width: 100%;
        height: 50px;
        object-fit: contain;
        background-image: linear-gradient(to bottom, $orange, $pinkish-red);
        margin:30px 0 66px 0;
        font-size: 1.3em;
        font-weight: 500;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }

    &__label{
      display: flex;
      &__label{
        font-size: 1em;
        line-height: 0.82;
        letter-spacing: 0.6px;
        text-align: center;
        width: 708px;
        color: $primary-color;
        cursor: pointer;
        margin: 0 auto;
      }
    }

    &__error{
      font-size: 12px;
      text-align: center;
      width: 100%;
      position: relative;
      top: -10px;
      color: red;
    }
  }
}

@media (max-width: 900px) {
  .Code{
    &__title{
      margin-top: 40px;
      font-size: 40px;
    }
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .Code{
    &__title{
      margin-top: 45px;
      font-size: 30px;
    }
  }
}