@import 'src/theme.scss';

.TabAmenityItem{
  display: flex;
  flex-direction: column;
  min-width: 150px;
  cursor: pointer;

  &__content{
    display: flex;
    flex-direction: row;
    margin-left: 20px;
  }

  &__image{
    padding-right: 10px;
    svg{
      height: 30px;
      max-width: 30px;
      transition-duration: 0.3s;
      path{
        stroke: transparent;
        fill: $primary-color;
      }
    }
    &__off{
      svg{
        path{
          stroke: transparent;
          fill: $gray;
          transition-duration: 0.3s;
        }
      } 
    }
  }
  &__info{
    &__title{
      &__on{
        font-size: 14px;
        color: $primary_color;
        transition-duration: 0.3s;
      }
    }
    &__amount{
      font-size: 10px;
      color: $gray;
      transition-duration: 0.3s;
      &__on{
        color: $dark;
        transition-duration: 0.3s;
      }
    }
  }

  &__selector{
    height: 3px;
    width: 100%;
    transition-duration: 0.3s;
    &__on{
      background-color: $primary_color;  
      transition-duration: 0.3s;
    }
  }
}