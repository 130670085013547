@import 'src/theme.scss';

.ModalTransferFunds { 
    &__title {
        padding-bottom: 17px;
        border-bottom: 1px solid $soft-gray;
        text-align: center;
    }
    &__form {
        margin: 25px auto;
        width: 85%;
        &__campos{
            width: 80%;
            margin-left: 85px;
            &__select{
                margin: 0;
                border: 0;
                display: inline-flex;
                padding: 0;
                position: relative;
                min-width: 0;
                flex-direction: column;
                vertical-align: top;
                width: 80%;
                margin-bottom: 15px !important;
                margin-top: 15px !important;
            }
        }
        &__obligatorio{
            font-size: 12px;
            margin-top: 65px;
            margin-bottom: 25px;
        }
        &__button-paymen { 
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 20px;
        }
    }
    &__transf{
        text-align: center;
        margin-top: 50px;
        margin-bottom: 30px;
    }  

    .botton-cancel {
        margin: 0px 10px;
    }
    
    .botton-save { 
        margin: 0px 10px;
    }
}

.danger-text{ 
    color: $pinkish-red !important;
}