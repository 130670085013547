.map {
  width: 100%;
  min-height: 80px;
  margin-top: 20px;
}

@media (min-width: 576px) {
  .map {
    min-height: 129px;
  }
}

@media (min-width: 768px) {
  .map {
    min-height: 135px;
  }
}
// modification map large
@media (min-width: 535px) {
  .map {
    min-height: 230px;
  }
}

@media (max-height: 750px) {
  .map {
    min-height: 130px;
  }
}
// modification map large
@media (max-height: 650px) {
  .map {
    min-height: 230px;
  }
}

@media (max-height: 550px) {
  .map {
    min-height: 120px;
  }
}


@media (max-height: 520px) {
  .map {
    min-height: 115px;
  }
}

@media (max-height: 500px) {
  .map {
    min-height: 110px;
  }
}

@media (min-width: 1200px) {
  .container {
    min-height: 320px;
  }
}