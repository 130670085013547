@import 'src/theme.scss';

.Neighbors {
  box-sizing: border-box;
  &__title {
    margin-bottom: 15px;
    margin-top: 20px;
    position: relative;
  }

  &__header {
    padding: 0px 30px 21px 0px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: row;
    background-color: $total-white;
    border-bottom: 1px solid $gray;
    border-top: 1px solid $gray;
    border-left: 1px solid $gray;
    width: 100%;
    z-index: 1;
    &__tabs {
      flex: 1;
      overflow-x: auto;
      &__actives { 
        width: 0px;
        min-width: 116px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        background-color: #e5e5e5;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
      }
    }
   
    &__options {
      button {
        margin-left: 10px;
        height: 39px;
      }
      &__email, &__phone {
        div {
          svg {
            margin-top: 5px;
            margin-right: 12px;
            height: 20px;
            path {
              fill: $primary_color;
            }
          }
        }
      }
      &__phone {
        div {
          svg {
            height: 28px;
          }
        }
      }
    }
  }

  &__table {
    position: relative;
    &__null {
      width: "100%";
      height: 100px;
      background-color: rgba($primary_color, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    } 
  }

  @media (max-width: 900px) { 
    .Neighbors__header {
      padding: 21px 30px 21px 30px;
      display: block;
    }
    .Neighbors__header__options button {
      margin-left: 0px;
    }

    .Neighbors__header__options {
      display: flex;
      justify-content: flex-start;
      margin-top: 10px;
      margin-left: 0px;
    }
    .Neighbors__table{
      overflow: auto;
    }
    .NeighborsTableHeader{
      min-width: 1000px;
    }
    .NeighborsTableItem{
      min-width: 1000px;
    }
    .NeighborPanel{
      overflow: auto;
    }
    
  }
}

.message_email{
  div{
    svg{
      height: 20px !important;
    }
  }
}

.Neighbors__body {
  margin: 0px 30px;
}

.card-list {
  height: auto !important;
}
