@import 'src/theme.scss';

.FormAmenity__Form__Question {
  text-transform: uppercase;
  color: $dark !important;
}

.FormAmenity {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex: 2 1 auto;
  border-left: 1px solid $gray;
  padding: 10px 20px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__Form {
    text-align: start;

    &__Header {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__Img {
        padding: 15px;
        background-color: $gray-5;
        border-radius: 135px;
        margin: 20px;

        svg {
          * {
            fill: $primary_color;
            mask: initial;
          }
        }
      }

      &__InputName {
        text-transform: uppercase;
      }
    }

    &__Upload {
      display: none;
      //display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 20px 0;

      &__Text {
        word-break: break-word;
        text-align: start;
        max-width: 400px;
      }
    }

    &__Input {
      margin-right: 20px!important;
      margin-bottom: 20px!important;
      text-transform: uppercase;
    }

    Button {
      float: right;
    }

    h5 {
      margin-top: 30px;
    }

    hr {
      margin: 0px;
    }
  }
}

.Delete {

  &:hover {
    cursor: pointer;
    svg {
      * {
        fill: $primary_color;
        mask: initial;
      }
    }
  }
}
