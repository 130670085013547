@import 'src/theme.scss';

@media (min-width: 0px) {
  .Remember{
    padding: 0px 20px;
    box-sizing: border-box;
    max-width: 560px;
    margin: auto;

    &__container{
      &__title{
        font-size: 40px;
        text-align: center;
        color: $primary_color;
        margin-bottom: 50px;
      }
    }

    &__text{
      font-size: 20px;
      text-align: center;
      color: $oxford;
      margin-bottom: 50px;
    }

    &__input{
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
    }

    &__button{
      button{
        width: 100%;
        height: 50px;
        object-fit: contain;
        background-image: linear-gradient(to bottom, $secondary-color, $secondary-color);
        margin:66px 0 25px 0;
        font-size: 1.3em;
        font-weight: 500;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }

    &__label{
      font-size: 14px;
      line-height: 1.14;
      color: $oxford;
      cursor: pointer;
    }
  }
  .Login{
    &__signup{
      &__text{
        span {
          color: $oxford;
          font-size: 15px;
        }
      }
    }
  }
}
