@import 'src/theme.scss';

.header-terms { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    height: 77px;
    border-bottom: 1px solid grey;
}

.buttons-reg {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.enter-okay { 
    width: 190px;
    height: 56px;
    margin: 0 31px 11px 259px;
    padding: 16px 43px 20px 44px;
    border-radius: 28px;
    border: 2px solid blue;
    text-align: center;
    font-family: $main-font;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: normal;
}

.test-free { 
    text-align: center;
    color: white !important;
    width: 190px;
    background-color: blue;
    height: 56px;
    margin: 0 59px 11px 24px;
    padding: 18px 41px;
    border-radius: 100px;
    font-family: $main-font;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: normal;
}

.img-logo { 
    width: 250px;
    height: 100%;
    display: flex;
    margin-left: 30px;
    align-items: center;
}

.logo { 
    height: 40px;
    cursor: pointer;
}

@media (max-width: 970px){
    .buttons-reg {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .ingresar { 
        text-align: center;
        font-size: 14px;
        margin: 5px 0px;
        cursor: pointer;
        border: 1px solid blue;
        padding: 10px 15px;
        border-radius: 50px;
        width: 84%;
    }
    .header-terms {
        width: 100%;
    }
    .imglogo { 
        margin-left: 15px;
    }
}