@import '../../../../theme.scss';


.PaySuccess {
  align-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 5px;
  text-align: center;
  margin: 20px;
  height: 90%;

  &__icono {
    width: 140px;
    height: 140px;
    margin: 0 auto;
  }

  &__content {
    overflow: auto;
    min-height: 280px;

    &__detail {
      margin: 0 auto;
      border-spacing: 5px;
      border-collapse: collapse;
      width: 100%;

      td {
        width: 50%;
      }

      &__concept {
        color: $oxford;
        font-family: $main-font;
        font-size: 16px;
        text-align: right;
        padding-right: 10px;
      }

      &__concept-value {
        font-family: $main-font;
        font-weight: 600;
        text-align: left;
      }

      &__content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 20px;
      }

      &__name {
        text-transform: capitalize;
      }
    }
  }


  &__buttons {
    min-height: 100px;
  }
}

.space-success{
  text-align: center;
  margin: 30px 0px;
}
.pay-error-email{
  color: $primary-color;
}