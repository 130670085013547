@import 'src/theme.scss';

.SectionResume{
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  &__title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    &__edit{
      color: $secondary_color !important;
      cursor: pointer;
      margin: 0px 15px;
    }
  }

  &__tabs{
    display: flex;
    flex-direction: row;

    &__tab{
      min-width: 150px;
      min-height: 50px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      label{
        text-align: center;
        width: 100%;
        color: $primary_color;
      }
    }

    &__double{
      min-width: 119px;
      min-height: 50px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-left: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &__line{
        border-right: 2px solid $gray-4;
        height: 30px;
        margin: 0px 10px;
      }

      div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 140px;
        
        label{
          text-align: center;
          width: 100%;
          color: $primary_color;
        }
      }
    }
  }
}

@media (max-width: 970px){
  .SectionResume{
    padding: 0px 20px;
    flex-direction: column;

    &__title{
      margin-bottom: 45px;
      flex-direction: column !important;
      align-items: flex-start !important;
      .SectionResume__title__edit {
        margin-left: 0 !important;
      }
    }
    &__tabs{
      flex-direction: column;
      width: 100%;
      margin: 15px 0px;
      &__tab{
        border-top: 1px solid gray;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-right: 10px;
        min-width: 10px;
      }

      &__double{
        margin-right: 10px;
        min-width: 10px;
        div{
          min-width: 10px;
          flex: 1;         
        }
      }
    }
  }
}
@media (max-width: 580px) {
  .SectionResume {
    padding: 0px 20px !important;
    flex-direction: column !important;
  }
  .SectionResume__tabs__double, .SectionResume__tabs__tab {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
  }
}