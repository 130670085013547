@import 'src/theme.scss';

.RequestItem {
    margin-top: 5px;
    border-bottom: 1px solid $soft-gray;
    cursor: pointer;
    .list-display {
        align-items: center;
    }
    .display-img {
        height: 45px;
    }
    .display-data {
        margin-left: 10px;
    }
    .space {
        margin-bottom: 0px !important;
    }
    .status {
        align-self: flex-start;
        margin: 10px 0;
        border-radius: 20px;
        padding: 2px 10px;
        justify-content: center;
        display: flex;
    }
}