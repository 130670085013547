@import "src/theme.scss";

.Body {
  padding: 20px;
  .left-column {
    max-width: 300px;
    margin-right: 20px;
  }
  .ant-btn-primary {
    margin: 0 auto 20px;
    display: block;
  }
}
