@import 'src/theme.scss';

.StepperWizard{
  display: flex;
  flex-direction: row;
}
.progress-bar {
  width: 100%;
  position: fixed;
  height: 3px;
  left: 0;
  top: 60px;
  z-index: 2;
  background: $light-gray;
  span  {
    transition: .5s ease all;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(233, 83, 13, .6);
  }
}