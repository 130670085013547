@import 'src/theme.scss';

.NeighborEdit{
  padding: 20px;
  flex: 1;
  &__title{
    font-size: 20px;
    margin-bottom: 25px;
  }

  &__container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 45px 0px;
  }

  &__content{
    max-width: 641px;
    width: 100%;

    &__image{
      display: flex;
      flex-direction: row;
      margin-bottom: 41px;
      padding-left: 21px;
      box-sizing: border-box;
      justify-content: center;
      align-items:center;

      &__img{
        margin-right: 36px;

        img{
          height: 90px;
          width: 90px;
          border-top-right-radius: 50%;
        }
      }

      &__title{
        flex: 1;
        display: flex;
        flex-direction: column;

        button{
          margin-top: 20px;
          width: 150px;
        }
      }
    }

    &__main{
      padding: 0px 21px;
    }

    &__item{
      margin-bottom: 20px;
    }

    &__title{
      background-color: rgba($primary_color, 0.3);
      border-radius: 3px;
      height: 40px;
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      padding-left: 21px;
      box-sizing: border-box;
    }

    &__data{
      background-color: $light-gray;
      border-radius: 5px;
      padding: 21px;
      box-sizing: border-box;
      margin-bottom: 10px;
    }

    &__more{
      color: $secondary_color;
      font-size: 14px;
      margin-bottom: 41px;
      padding: 0px 21px;
      cursor: pointer;
    }

    &__options{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      button{
        margin: 0px 10px;
        min-width: 150px;
      }
    }
  }
}