@import 'src/theme.scss';

.QuantitiesPayService{
    &__quantities {
        &__col {
          display: flex;
          justify-content: space-between;
          margin: 30px 0px;
          &__desc { 
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            font-family: $main-font;
          }
          &__price { 
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            font-family: $main-font;
          }
          &__total { 
            font-size: 20px;
            font-weight: 900;
            font-family: $main-font;
          }
          &__priceTotal { 
            font-size: 20px;
            font-weight: 900;
            font-family: $main-font;
          }
        }
      }
}