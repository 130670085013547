@import 'src/theme.scss';

.ModalComposeMessage {
  margin: 10px 10px;
 &__title{
     padding-bottom: 17px;
     border-bottom: 1px solid $soft-gray;
     text-align: center;
 }
 &__container{
     padding: 20px 30px;
    &__row{
        &__template{
            width: 60%;
        }
    }
    &__for{
        margin: 30px 0px;
    }
    &__subject{
        margin: 30px 0px;
        &__input{
            width: 100%;
            border-radius: 3px;
            border-color: $primary-color !important;
            height: 35px;
            margin-top: 10px;
            border-style: solid;
            border-width: 1px;
            padding: 0px 10px;
        }
    }
    &__message{
        margin: 30px 0px;
        height: 140px;
        overflow-y: scroll;
    }
    &__buttons{
        display: flex;
        justify-content: center;
        &__two{
            margin-left: 40px;
        }
    }

 }
}


.css-yk16xz-control{
    border-color: $primary-color !important;
}

.css-26l3qy-menu{
    color: $primary-color;
}

.css-1rhbuit-multiValue{
    background-color: #e6faf6 !important;
    color: $secondary-color !important;
}
.css-12jo7m5{
    color: $secondary-color !important;
}

.css-1pahdxg-control:hover{
    border-color: $primary-color !important;
    box-shadow: 0 0 0 1px $secondary-color !important;
}

.body-text1{
    color: $primary-color !important;
}
.space{ 
  margin: 15px 0px !important;
}
.ql-snow{
    padding: 5px 10px !important;
}
