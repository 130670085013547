@import 'src/theme.scss';

.Accordion{
  padding-bottom: 20px;
  box-sizing: border-box;

  &__panel{
    transition-duration: 0.5s;
    margin-bottom: 15px;
    min-height: 100px;
    overflow: auto;
    padding-bottom: 30px;
    .TableAccordion {
      min-width: 1000px;
    }
  }
  &__panelClose{
    height: 0px;
    overflow: hidden;
    transition-duration: 0.5s;
  }
  &__more{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    label{
      color: $secondary_color;
      cursor: pointer;
      font-size: 12px;
    }
  }
}