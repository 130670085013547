@import 'src/theme.scss';

.InitAccount{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $dark;

  &__container{
    flex: 1;
    max-width: 1000px;
    width: 90%;
  }

  &__card{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 50px;
    border-radius: 6px;
    margin: 0;
    
  }

  &__image{
    justify-content: center;
    margin-bottom: 10px;
    display: flex;
    img {
      width: 50%;
    }
  }

  &__title{
    text-align: center;
    font-size: 28px;
    color: $primary_color;
    margin-bottom: 11px;
  }
  &__text{
    text-align: center;
    font-size: 16px;
    color: $oxford;
    margin-bottom: 13px;
  }
  &__cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 13px;
  }
  &__download{
    text-align: center;
    font-size: 22px;
    color: $secondary_color;
    margin-bottom: 30px;
    margin-top: 30px;
    cursor: pointer;
    img{
      height: 22px;
      margin-right: 10px;
    }
  }
  &__amount{
    background-color: rgba(223, 224, 235, 0.27);
    border-radius: 20px;
    padding: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 10px 5px;

    &__container-register {
      display: flex; 
      &__automatico {
        width: 50%;
        margin: 15px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid  $secondary-color;
        &__title {
          text-align: center;
        }
      }

      &__manual { 
        width: 50%;
        margin: 15px;
        display: flex;
        flex-direction: column;
      }
    }

    strong{
      font-size: 20px;
      color: black;
    }
    &__rules{
      display: flex;
      flex-direction: row;
      margin-top: 15px;

      &__image{
        display: flex;
        align-items: center;
        padding-top: 0px;
        margin-right: 25px;
        img{
          height: 47px;
          width: 60px;
        }
      }
      &__items{
        margin-bottom: 5px;
        color: $oxford;
        padding-top: 0px;
        &__text{
          font-size: 19px;
        }
        div{
          &:before{
            content: "";
            display: inline-block;
            margin-right: 10px; 
            border-radius: 50px;
            height: 10px;
            width: 10px;
            background: $primary_color;
            
          }
        }
      }
    }
  }

  &__other{
    margin-top: 17px;
    margin-bottom: 27px;
    text-align: center;
    color: $dark;
    font-size: 20px;
    font-weight: 500;
    span{
      color: $primary-color;
      cursor: pointer;
    }
  }
  &__options{
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      background-color: $secondary-color;
    }
  }
}

.title-regist { 
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

ul {
  padding: 20px;
}

ul li { 
  font-size: 15px;
  margin: 5px;
}

.buttnon-register { 
  width: 150px;
  height: 40px;
  object-fit: contain;
  border-radius: 4px;
  border: solid 1px $gray;
  margin: auto;
  color: $gray;
} 

.manual {
  border-color: $secondary-color;
  color: $secondary-color;
  cursor: pointer;
  margin-top: 22px;
}

.manual:hover { 
  background-color: $secondary-color;
  color: $total-white;
}

@media (max-width: 650px) {
  .InitAccount {
    &__container {
      width: 100%;
    }
    &__image {
      display: none;
    }
    .ActiveCard__row__image {
      display: none;
    }
    .InitAccount__amount strong {
      font-size: 18px;
    }
    &__card {
      padding: 20px 15px;
    }
    &__amount__rules {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      &__image {
        margin-right: 0;
      }
      &__items {
        width: 100%;
      }
    }
  }
  .ModalInt__options {
    flex-direction: column;
    button {
      & + button {
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 400px) {
  .ModalInt img {
    margin-top: -100px;
    margin-bottom: 70px;
  }
}