@import 'src/theme.scss';
.alert-dialog-link{
    color: $secondary_color;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
}

.alert-dialog-description-b{
    
    color: $black;
    font-size: 1rem;
    font-family: $main-font;
    
}
