@import '../../../../theme.scss';

.PayServiceImobly {
  padding: 20px;
  font-family: $main-font;
  &__goback {
    left: 0px;
    top: 0px;
    color: $secondary-color !important;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-family: $main-font;
    label {
      color: $primary-color !important;
    }
  }
  &__title {
    padding: 20px;
    border-bottom: 1px solid $border-color;
  }
  
  &__content {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    &__summary {
      display: flex;
      flex-direction: column;
      width: 50%;
      h5 {
        text-align: left;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: 10px 0px;
      }
      &__properties{
        border-bottom: 1px solid $border-color;
        border-top: 1px solid $border-color;
        padding: 15px 0px;
        height: 400px;
        overflow-y: scroll;
        padding-right: 20px;
      }
    }
    &__payment {
      display: flex;
      flex-direction: column;
      width: 40%;
      &__description{
        text-align: justify;
        border-top: 1px solid $border-color;
        margin-bottom: 20px;
        padding: 20px 0px;
      }
      &__method {
        border-top: 1px solid $border-color;
        padding: 26px 0px;
        label {
            width: 154px;
            height: 16px;
            font-family: $main-font;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
        }
        &__image { 
            display: flex;
            justify-content: space-between;
            width: 40%;
            margin: 10px 0px;
        }
      }
      &__cards {
        &__inputs {
            display: flex;
            flex-direction: column;
            margin: 42px 0px;
            width: 80%;
            label {
                width: 154px;
                height: 16px;
                font-family: $main-font;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1px;
            }
            input {
                width: 453px;
                height: 35px;
                border-radius: 3px;
                border: solid 1px $primary-color;
                background-color: $border-color;
            }
        }
        &__col {
          display: flex;
          margin: 42px 0px;
          width: 88%;
          label {
            width: 154px;
            height: 16px;
            font-family: $main-font;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
          }
          input {
            width: 206px;
            height: 35px;
            border-radius: 3px;
            border: solid 1px $primary-color;
            background-color: $border-color;
          }
        }
        &__button {
          button {
            width: 452px;
            height: 52px;
            border-radius: 4px;
            background-color: green;
            color: white;
          }
        }
      }
    }
  }
}

.tamanio{ 
  width: 80%;
}

.paymentService { 
  width: 452px;
  height: 52px;
  border-radius: 4px;
  background-color: $primary-color;
  font-family: $main-font;
}

.account_imobly{ 
  color: $primary-color;
}

.space-pay{ 
  margin: 8px 0px;
}

.body-text1 { 
  color: $dark !important;
  margin-bottom: 0px;
}