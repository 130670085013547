@import 'src/theme.scss';

.SectionExpenditure{
  flex: 1;
  .graph {
    margin-left: 10px;
    .ant-progress-circle.ant-progress-status-success .ant-progress-text {
      color: $primary-color-ultra !important;
    }
  }
}

.CirclePay {
  background: $primary-color-ultra;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 10px;
}
.CirclePay2 {
  background: $gray-3;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 10px;
}