@import 'src/theme.scss';

.QuotaFotter{
  background-color: $light-gray;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 17px;
  border-radius: 5px;

  &__row{
    display: flex;
    flex-direction: row;
    padding: 5px;

    &__label{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px;
      color: $dark;
      font-weight: normal;
    }

    &__amount{
      width: 200px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      font-weight: 500;
      color: $dark;
    }
  }
}

@media(max-width: 900px) {
  .QuotaFotter{
    &__row{
      justify-content: space-between;
      &__amount{
        width: auto;
      }
      &__label{
        justify-content: flex-start;
      }
    }
  }
}