$primary-color:         #0f8570;
$secondary-color:       #14b497;
$third-color:           #19e1bd;
$primary-text:          #3E4247;
$facebook:              #4267b2;
$google:                #d74634;
$primary-color-ultra:   #7cf2e8;
$dark-green:            #0f8570;
$light-green:           #19e1bd;
$black:                 #000000;
$dark:                  #363636;
$oxford:                #6c7281;
$gray:                  #cbcdd8;
$soft-gray:             #d5d8e5;
$gray-4:                #bebebe;
$light-gray:            #fafafa;
$gray-5:                #f0f0f0;
$gray-3:                #e5e5e5;
$total-white:           #ffffff; 
$pinkish-red:           #e32b4d;
$red:                   #e95772;
$soft-red:              #fbdfe5;
$orange:                #d95c2b;
$light-orange:          #e98f3d;
$eggshell-blue:         #cdf9f1;
$shadow:                rgba(229,229, 229,0.5);
$white:                 #ffffff;
$border-color:          #d5d8e5;
$blue-opacity:          #e2e8ff;
$light-blue:            #5473e9;
$orange-opacity:        #ffdfc3;
$light-orange:          #e98f3d;

$main-font: 'Poppins', sans-serif;
