@import 'src/theme.scss';

.ColCard {
  width: 100%;
  height: 100%;
  .sections {
    align-items: center;
    box-sizing: border-box;
    & > div {
      position: relative;
      padding: 0 10px;
      &:first-child {
        padding-left: 0;
      }
      &:not(:last-child) {
        &::after {
          position: absolute;
          content: "";
          width: 2px;
          background-color: rgba($primary_color, 0.2);
          height: 100%;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    div {
      label {
        margin-top: 5px;
        color: $primary_color;
        width: 100%;
        margin-bottom: 5px;
        text-align: center;
      }
      .heading-3 {
        text-align: center;
      }
    }
  }
}