@import 'src/theme.scss';

.heading, h1 {
    font-family: $main-font;
    font-size: 40px;
    color: $dark;
    letter-spacing: 1px;
}
  
.heading-2, h2 {
    font-family: $main-font;
    font-weight: 600;
    font-size: 22px;
    color: $dark;
}
  
.heading-3, h3 {
    font-family: $main-font;
    font-weight: 500;
    font-size: 20px;
    color: $dark;
}
  
.heading-4, h4 {
    font-family: $main-font;
    font-weight: 600;
    font-size: 18px;
    color: $dark;
}
  
.heading-5, h5 {
    font-family: $main-font;
    font-weight: 500;
    font-size: 16px;
    color: $dark;
}
  
.subtitle {
    font-family: $main-font;
    font-weight: 400;
    font-size: 14px;
    color: $dark;
}
  
.subtitle-2 {
    font-family: $main-font !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    letter-spacing: 1px !important;
}
  
.regular-text {
    font-family: $main-font;
    font-weight: 400;
    font-size: 16px;
    color: $oxford;
    margin-bottom: 0px;
}

.body-text1{
    font-family: $main-font;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: normal;
    color: $oxford;
}

.body-text3 {
    &, *:not(.MuiIcon-root) {
        font-family: $main-font !important;
        font-size: 14px !important;
        font-weight: normal !important;
        letter-spacing: normal !important;
    }
}