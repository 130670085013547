@import 'src/theme.scss';

.Founds {  
  &__divider {
    width: 100%;
    height: 1px;
    background-color: rgba(142, 150, 158, 0.52);
    margin: 20px 0px;
  }
  &__title {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark-green;
  }
  &__header{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    background-color: $dark-green;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &__img{
      svg{
        height: 25px;
        width: 60px;
        path{
          stroke: $total-white;
          fill: transparent;
        }
      }
    }

    &__text{
      font-size: 14px;
      color: $total-white;
    }
  }
  &__table {
    background-color:$light-gray;
    border-radius: 10px;
    box-shadow: 0 1px 1px 0 rgba(142, 150, 158, 0.52);
    margin-bottom: 20px;
    //margin: 20px 0px;
    &__header {
      background-color: $primary-color-ultra;
      display: flex;
      justify-content: space-between;
      padding: 8px 30px;
      //border-top-left-radius: 10px;
      //border-top-right-radius: 10px;
      font-size: 14px;
      color: $oxford;

      &__name {
        flex: 1;
      }
    }
    &__headerTab {
      width: 15%;
      text-align: center;

      &__name {
        flex: 1;
      }
    }
    &__headerTabEdit {
      width: 5%;
      text-align: center;

      &__name {
        flex: 1;
      }
    }
    &__body {
      &__item {
        display: flex;
        justify-content: space-between;
        padding: 8px 30px;
        color: $dark;
        font-size: 14px;
        &__sectionName {
          flex: 1;
        }
        &__section {
          width: 15%;
          text-align: center;
        }
        &__sectionEdit {
          width: 5%;
          text-align: center;
          justify-content: center;
          display: flex;
        }
      }
    }
  }
}

@media(max-width: 1000px) {
  .Founds{
    &__table{
      overflow: auto;
      &__header{
        min-width: 1000px;
      }
      &__body{
        min-width: 1000px;
        &__item{
          min-width: 1000px;
        }
      }
    }
  }
}

