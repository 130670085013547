@import '../../theme.scss';

.Calendar {
    .calendar {
        margin-bottom: 10px;
        &-month_header {
            display: flex;
            flex-direction: row;
            position: relative;
            margin: 0 0 10px;
            .is-prev {
                top: 50%;
                right: 30px;
                margin-top: -6px;
                transform: rotate(-225deg) translateY(-50%);
            }
            .is-next {
                top: 50%;
                right: 0;
                margin-top: -1px;
                transform: rotate(-45deg) translateY(-50%);
            }
            &_title {
                display: flex;
                font-size: 18px;
                font-weight: 600;
                text-align: left;
                color: #000000;
            }
        }
        &-header_button {
            width: 8px;
            height: 8px;
            background-color: transparent;
            padding: 0px;
            border: none;
            border-radius: 2px;
            border-right: 2.3px solid $secondary-color;
            border-bottom: 2.3px solid $secondary-color;
            outline: none;
            position: absolute;
            cursor: pointer;
        }
        &-days_of_week {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            &_day {
                flex: 1;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-family: $main-font;
                font-size: 12px;
                color: $gray-4;
            }
        }
        &-week {
            display: flex;
            flex-direction: row;
            .is-current_month {
                color: #888C91;
            }
            .is-next_month, .is-prev_month {
                color: rgba(#888C91, 0.29);
            }
            .is-highlighted {
                background-color: $gray-5;
                color: $dark;
                &:first-child {
                    border-top-left-radius: 50%;
                    border-bottom-left-radius: 50%;
                }
                &:last-child {
                    border-top-right-radius: 50%;
                    border-bottom-right-radius: 50%;
                }
            }
            .is-start_selection {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .is-end_selection {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            .is-selected {
                position: relative;
                background-color: $secondary-color;
                border-radius: 50%;
                width: 100%;
                height: 100%;
                z-index: 1;
                & + .is-highlighted {
                    position: relative;
                    &::before {
                        background-color: $gray-5;
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 100%;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                    }
                }
                color: white;
            }
        }
        .events {
            position: absolute;
            bottom: 6px;
            left: 0;
            padding: 0px 5px;
            width: 100%;
            justify-content: center;
            span {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                &:not(:first-child){ 
                    margin-left: 5px;
                }
            }
        }
        &-week-day {
            position: relative;
            flex: 1;
            padding: 10px;
            font-size: 10px;
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            height: 37px;
        }
    }
}
    