@import 'src/theme.scss';

.TabItem {
  display: flex;
  flex-direction: column;
  min-width: 220px;
  cursor: pointer;
  padding: 22px 10px;
  border-radius: 7px 7px 0 0;
  text-align: center;
  color: $oxford;
  white-space: nowrap;
  z-index: 1;
  &.active{
    background: white;
    color: $primary_color;
    box-shadow: 2px 0 5px 0 rgba(213, 216, 229, 0.29);
    border: 1px solid #d5d8e5;
    border-bottom: none;
  }
}