@import 'src/theme.scss';

// ********************* ADDRESS ********************* //

.Address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
    &__text{
        font-size: 15px;
        margin-top: 29px;
        line-height: 1.33;
        text-align: center;
        margin-bottom: 21px;
        color: $oxford;
    }
    &__options{
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-top: 20px;
        position: relative;

        button {
            background-image: linear-gradient(to bottom, $secondary-color, $secondary-color);
        }

        &__info{
            position: absolute;
            text-align: center;
            left: calc(50% - 100px);
        }
    }
}