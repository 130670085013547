@import 'src/theme.scss';

.ant-card {
    border-radius: 11px !important;
    box-shadow: 4px 5px 10px 0 rgba($gray, 0.5) !important;
    background-color: $white !important;
    &-body {
        padding: 20px !important;
    }
}

