@import 'src/theme.scss';

.Table {
    .MuiTable-root {
        border-collapse: inherit;
    }
    .MuiTableHead-root {
        background-color: $gray-5;
    }
    .MuiTableCell-head {
        font-weight: 400;
        font-size: 14px;
    }
    .MuiTableCell-root {
        border: none;
        &.MuiTableCell-body {
            font-size: 14px;
            .placeholder {
                opacity: .5;
            }
            svg {
                cursor: pointer;
            }
            span {
                &.negative {
                    color: #e95772;
                }
            }
        }
    }
    .MuiTableRow-root:not(:last-child):not(.collapsed):not(.detail-component) {
        .MuiTableCell-body {
            border-bottom: 1px solid #cbcdd8;
        }
        .loading-container {
            border-bottom: none;
        }
    }
    .loading-container {
        padding: 0;
    }
    .MuiTableRow-root:not(:last-child) {
        .MuiLinearProgress-root {
            margin-bottom: -4px;
        }
    }
    .MuiLinearProgress-root {
        background-color: transparent;
        .MuiLinearProgress-barColorSecondary {
            background-color: $gray;
        }
    }
    button.action-button{
        border-radius: 15px;
        padding: 0px 10px;
        min-width: 95px;
        min-height: 24px;
        font-size: 14px;
        aside {
            &:before{
                content: '';
                display: inline-block;
                width: 10px;
                height: 5px;
                border-bottom: 2px solid $total-white;
                border-left: 2px solid $total-white;
                transform: rotate(-50deg);
                bottom: 4px;
                position: relative;
                margin-right: 4px;
            }
        }
    }
    button.action-button:disabled, button.action-button[disabled]{
        border: none;
        background-color: $primary_color;
        color: $total-white;
        font-weight: 200;
    }
    .detail-component {
        & > .MuiTableCell-root {
            padding: 0;
        }
    }
    .detail-outline {
        & + .detail-component {
            & > .MuiTableCell-root {
                border: 1px solid #0f8570;
                border-bottom-width: 5px;
                border-top: none;
            }
        }
        & > .MuiTableCell-body {
            border-color: #0f8570;
            border-style: solid;
            border-left-width: 0;
            border-right-width: 0;
            border-top-width: 1px;
            border-bottom-width: 0 !important;
            &:first-child {
                border-left-width: 1px;
            }
            &:last-child {
                border-right-width: 1px;
            }
        }
    }
    .MuiOutlinedInput-input {
        padding: 4.5px 14px;
    }
}