@import 'src/theme.scss';

.AmenityCounter{
  justify-content: center;
  align-items: flex-end;

  &__display {
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    border-bottom: 1px solid $light-gray;

    &__option {
      padding: 20px;
      margin: 10px;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      white-space: nowrap;

      * {
        cursor: pointer;
      }

      &:hover {
        svg {
          * {
            fill: $primary_color;
            mask: initial;
          }
        }
      }
    }

    &__item{
      width: 84px;
      justify-content: center;
      align-items: center;

      &__active {
        svg {
          * {
            fill: $primary_color;
            mask: initial;
          }
        }
      }

      &__check {
        display: none;
        &__visible {
          width: 33px;
          height: 33px;
          position: absolute;
          top: 50px;
          right: 35px;
        }
      }
    }
  }
}

.CustomInput {
  display: flex;
  flex: 1 !important;
  align-items: center;
  font-size: 10px;
  border: 1px solid $soft-gray;
  box-shadow: none!important;
  border-radius: 10px!important;
  max-width: 100px;
  &__Input {
    text-align: center;
    display: initial !important;
    input {
      text-align: center;
    }
  }

  &__Button {
    padding: 0px!important;
  }

}

#icon-menos {
  margin-left: 10px !important;
}

@media (max-width: 460px) {
  .AmenityCounter{
    &__display {
      &__option {
        padding: 10px;
        margin: 5px;
      }
    }
  }

}
