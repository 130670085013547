@import 'src/theme.scss';

.AccordionDisplay{
    display: flex;
    color: $oxford;
    align-items: center;
    font-size: 14px;
    height: 40px;
    position: relative;
    padding: 10px 5px;
    &__icon{
        padding-right: 15px;
        padding-left: 15px;
    }
    &__options{
        display: flex;
        flex-direction: row;
        font-size: 14px;
        min-width: 85px;
        color: $oxford;
        &__input{
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $secondary_color;
            outline: none;
            color: $secondary_color;
            text-align: center;
            font-size: 14px;
            font-style: italic;
            width: 100%;
            max-width: 220px;
            margin-right: 10px;
            margin-left: 10px;
            box-sizing: border-box;
            &.invalid {
                border-bottom: 1px solid red;
                color: red;
            }
        }
        &__input::placeholder{
            color: $secondary_color;
        }
    }
    &__button{
        position: absolute;
        right: 10px;
        img{
            height: 15px;
            cursor: pointer;
        }
        button{
            padding: 10px;
        }
    }
}
