@import 'src/theme.scss';

@media (min-width: 0px) {
  .Login{
    padding: 0px 20px;
    padding-top: 40px;
    box-sizing: border-box;
    max-width: 500px;
    margin: auto;

    &__title{
      display: flex;
      flex-direction: column;
      text-align: center;
      color: $primary_color;
      font-size: 3vmax;
      margin-bottom: 47px;

      &__logo {
        height: 78px;
        width: 250px;
        margin: 0 auto;
      }

      small{
        font-size: 12px;
        color: $dark;
      }
    }

    &__panel{
      width: 100%;
      border-radius: 6px;
      height: auto;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      margin-right: 14px;
      &__entry{
        flex: 1;
        display: flex;
        flex-direction: row;
        &__select{
          width: 8px;
          &__active{
            background-color: $primary_color;
          }
        }
        &__input{
          padding-right: 30px;
          width: 100%;

          span{
            color: $oxford;
            font-size: 12px;
            text-transform: uppercase;
          }

          &__input{
            width: 100%;
            height: 20px;
            border: 1px solid $soft-gray;
            border-radius: 5px;
            background-color: rgba(224, 231, 255, 0.2);
            font-size: 18px;
            font-weight: 500;
            color: $dark;
            outline: none;
            padding: 12px 14px;
          }
        }
        &__line{
          margin-bottom: 22px;
        }
        &__shadow{
          box-shadow: 10px 15px 30px -10px rgba(7, 8, 139, 0.16)
        }
      }
    }

    &__error{
      color: $pinkish-red;
      display: flex;
      flex-direction: column;
    }

    &__reset{
      text-align: right;
      margin-bottom: 20px;
      cursor: pointer;
      *{
        color: $secondary_color;
      }
    }

    &__button{
      margin-bottom: 10px;
      button{
        background-color: $secondary_color;
        width: 100%;
        padding: 10px;
        * {
          color: $total-white;
          text-transform: capitalize;
          font-size: 18px;
        }
      }
    }
    
    &__options{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 17px;

      &__span {
        border-bottom: 1px solid gray;
        width: 20px;
        height: 3px;
        margin: 0px 10px;
      }

      &__divider{
        background-color: $soft-gray;
        height: 1px;
        width: 100%;
        margin-top: 15px;
      }

      span{
        color: $oxford;
        font-size: 15px;
      }
    }

    &__other{
      margin-bottom: 10px;
      text-align: center;
    }

    &__social{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;

      &__facebook{
        margin: 0px;
        &__button{
          border: none;
          background-color: $facebook;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: $total-white;
          width: 100%;
          height: 40px;
          border-radius: 20px;
          cursor: pointer;
          svg {
            font-size: 30px;
          }

          &__containerLogo{
            display: flex;
            flex-direction: row;
            justify-content: 'center';

            &__logo {
              margin-right: 10px;
            }

            span{
              font-size: 15px;
              margin-top: 4px;
            }
          }
        }
      }

      &__google{
        margin-bottom: 20px;
        &__button{
          background-color: $total-white;
          border-radius: 20px !important;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: $total-white;
          width: 100%;
          height: 40px;
          border: 1px solid #d5d8e5 !important;
          box-shadow: none !important;
          border-radius: 20px;
          cursor: pointer;
          & > div {
            background: none !important;
          }
          svg {
            font-size: 40px;
          }

          &__logo{
            margin-right: 10px;
          }

          span {
            color: $dark;
            font-size: 15px;
            line-height: 40px;
          }
        }
      }
    }

    &__signup{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 5px;
      &__text {
        span {
          color: $oxford;
        }
      }
      &__register{
        margin-left: 10px;
        cursor: pointer;
        * {
          color: $secondary_color;
        }
      }
    }
  }
}


@media (max-width: 970px) {
  .Login{
    padding-top: 0px;
    &__title{
      &__logo {
        height: 70px;
        width: 230px;
      }
    }
  }
}

