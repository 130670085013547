.terms { 
    padding: 80px;
    text-align: justify;
}

.title-notice {
    margin-bottom: 30px;
    font-weight: bold;
}

h2 { 
    font-weight: bold;
    margin-bottom: 30px;
}

.date-update {
    margin-bottom: 60px;
    font-weight: bold;
    font-size: 20px;
}


section { 
    margin-bottom: 30px;
}

@media (max-width: 970px){
    .terms {
        padding: 30px;
    }
}