@import 'src/theme.scss';

.DrawerItem{
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &__view{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition-duration: 0.3s;
    padding: 18px 12px;
    &__on{
      background-color: $secondary-color;
      transition-duration: 0.3s;

      .DrawerItem__text {
        font-size: 12px;
        color: $total-white;
        text-align: center;
        word-wrap: break-word;
        width: -webkit-fill-available;
      }

      .DrawerItem__number {
        font-size: 12px;
        color: $total-white;
        text-align: center;
      }
    }
  }

  &__svg{
    height: 40px;
    max-width: 40px;
    transition-duration: 0.3s;
    path{
      stroke: transparent;
      fill: $gray;  
    }
  }

  &__svgOn{
    height: 40px;
    max-width: 40px;
    transition-duration: 0.3s;
    path{
      stroke: transparent;
      fill: $total-white; 
    }
  }

  &__text{
    font-size: 12px;
    color: $oxford;
    text-align: center;
    margin-top: 8.5px;
    word-wrap: break-word;
    width: -webkit-fill-available;
  }

  &__number{
    font-size: 12px;
    color: $oxford;
    text-align: center;
    font-weight: 500;
    margin: 0px 10px;
  }
}

@media(max-width: 900px) {
  .DrawerItem{
    &__view{
      height: 100%;
    }
  }
}