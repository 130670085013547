@import 'src/theme.scss';

.BudgetDrawer{
  background-color: $light-gray;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 110px;
  &__section{
    &__title{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 5px 0px;
      color: $total-white;
      font-size: 14px;
      font-weight: 200;
      background-color: $secondary-color;
    }
  }
}

@media (max-width: 901px) {
  .BudgetDrawer {
    flex-direction: row;
    display: flex;
    left: 0;
    background: $light-gray;
    width: 100%;
    flex: 0;
    height: auto;
    z-index: 2;
    box-shadow: 0 2px 4px 0 rgba(148, 157, 169, 0.5);
    overflow: auto;
    &__section {
      flex-direction: row;
      display: flex;
      &__title {
        padding: 0 20px;
        height: auto;
      }
    }
    &Item {
      min-width: 120px;
    }
  }
}