@import 'src/theme.scss';

.Dropzone {
  background-color: $total-white;
  font-size: 16px;
  border: 1px solid $light-green;
  width: 388px;
  height: 388px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  img{
    width: 60px;
    transform: rotate(180deg);
    margin-bottom: 30px;
  }

  label{
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.FileInput {
  display: none;
}
  