@import 'src/theme.scss';

.TrialEditor {
  min-width: 150px;
  input {
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  .MuiCircularProgress-root {
    min-width: 15px;
  }
}