@import 'src/theme.scss';

.QuotaItem{
  border-bottom: solid 1px $eggshell-blue;
  border-right: 1px solid rgba($primary_color,0.5);
  border-left: 1px solid rgba($primary_color,0.5);
  padding: 4px 0px;
  color: $oxford;
  display: flex;
  flex-direction: row;
  .CurrencyInput {
    width: 120px;
    text-align: center;
    height: 33px;
    input {
      width: 100%;
      text-align: center;
      font-size: 14px;
      height: 100%;
      font-style: italic;
      color: $oxford;
      background-color: transparent;
      border: none;
      outline: none;
    }
    & + .QuotaItem__item__fee__input__arrow {
      margin-left: -4px;
      height: 30px;
      margin-top: -3px;
      z-index: 1;
    }
  }
  &__item{ 
    font-size: 14px;
    &__number{
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__name{
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__balance{
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;     
    }
    &__interest{
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__fee{
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      &__input{
        color: $oxford;
        font-style: italic;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
          margin-left: 5px;
        }
        &__arrow{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          color: $total-white;
          transition-duration: 0.3s;
          cursor: pointer;
          span{
            margin-left: 0px;
          }

          &__up{
            background-color: $secondary-color;
            transform: rotate(180deg);
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            transition-duration: 0.3s;
          }
          &__down{
            background-color: $pinkish-red;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            transition-duration: 0.3s;
          }
        }
        &__save{
          margin-left: 10px;
          transition-duration: 0.3s;
          cursor: pointer;
          :hover{
            background-color: greenyellow;
            transition-duration: 0.3s;
            font-size: 30px;
            border-radius: 5px;
          }
        }
      }
      
    }
  }

  &__last{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 1px solid rgba($primary_color,0.5);
  }
}

.inavlid-input-balance { 
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiInput-root.Mui-disabled {
        background: $gray-4;
        width: 120px;
        text-align: center;
        color: $oxford;
        font-style: italic;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 4px;
  }
}
