@import 'src/theme.scss';

.RejectModal {
    form {
        padding: 0 70px;
        label {
            text-align: center;
            padding-bottom: 20px;
        }
    }
    .icon {
        margin: 30px 0;
    }
}