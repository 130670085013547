@import 'src/theme.scss';

.TabView {
  .tabs {
    position: relative;
    overflow-x: auto;
    flex: none;
    &::before {
      position: absolute;
      bottom: 0;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $soft-gray;
    }
  }
}