.contenedor {
    text-align: justify;
    padding: 80px;
} 
.title-privacy { 
    margin-bottom: 30px;
    font-weight: bold;
}

section {
    margin-bottom: 20px;
    margin-top: 20px;
}

@media (max-width: 970px){
    .contenedor {
        padding: 30px;
    }
}