@import 'src/theme.scss';

.ModalAmenity{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  color: $oxford;
  padding-top: 52px;
  padding-bottom: 71px;
  &__inline{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
  }
  &__item{
    padding-right: 20px;
    padding-left: 20px;
    width: 50%;
  }
}