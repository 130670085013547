@import 'src/theme.scss';

.AddTowerPage{
    padding-top: 70px;
    &__body{
        padding: 0px 30px;
        display: flex;
        flex-direction: row;
        margin: auto;
        max-width: 1300px;
        &__add{
            //width: 330px;
            padding: 0px 19px 20px 0;
        }
        &__pane{
            width: calc(100% - 270px);
            padding-right: 10px;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
    &__title{
        padding-top: 15px;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
    }
    &__back{
        border: none;
        background-color: transparent;
        color: $secondary-color;
        cursor: pointer;
        i{
            color: $secondary-color;
            position: relative;
            top: 10px;
            font-size: 30px;
        }
    }
    &__options{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 60px;

        button {
            background-image: linear-gradient(to bottom, $secondary-color, $secondary-color);
            text-transform: uppercase;
        }
    }
    &__add{
        position: absolute;
        right: 30px;
    }
}

@media(max-width: 1100px) {
    .Form__focusOn {
        padding: 0;
        border-radius: 0;
    }
    .AddTower {
        &Form{
            &__form {
                height: auto;
                & > .MuiFormControl-fullWidth-94, & > .Form__focus {
                    width: auto;
                    display: inline-flex;
                    margin-right: 20px;
                    margin-bottom: 0 !important;
                    &:first-child {
                        min-width: calc(90vw - 530px);
                    }
                    label {
                        position: relative;
                        white-space: nowrap
                    }
                    .AddTowerForm__separador {
                        margin-bottom: 0 !important;                    
                    }
                }
            }
        }
        &Page {
            &__body {
                width: 90%;
                flex-direction: column;
                &__add, &__pane {
                    .SimpleCard {
                        width: 100% !important;
                        height: auto !important;
                    }
                    width: 100%;
                }
            }
        }
    }
}
@media(max-width: 790px) {
    
    .AddTower {
        &Form{
            &__form {
                height: auto;
                & > .MuiFormControl-fullWidth-94, & > .Form__focus {
                    width: 100%;
                    display: flex;
                    margin-right: 0;
                    &:not(:last-child) {
                        margin-bottom: 30px !important;
                    }
                    .AddTowerForm__separador {
                        margin-bottom: 20px !important;                    
                    }
                }
            }
        }
    }
}