@import 'src/theme.scss';

.ActiveCard{
  width: 50%;
  padding: 10px 10px;
  box-sizing: border-box;

  &__row{
    background: $total-white;
    border: 1px solid $eggshell-blue;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    padding: 15px 8px;

    &__image{
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      &__svg{
        svg {
          * {
            fill: $primary_color !important;
            mask: initial !important;
          }
        }
      }

    }

    &__text{
      &__title{
        font-size: 20px;
        font-weight: 500px;
        margin-bottom: 7px;
      }
      &__data{
        display: flex;
        flex-direction: row;
        color: $oxford;
        &__ref{
          color: $primary_color;
        }
      }
    }
  }
}

.ActiveCardMini{
  width: 33.33%;
  padding: 5px 5px;
  box-sizing: border-box;

  &__row{
    display: flex;
    flex-direction: row;
    padding: 15px;

    &__image{
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      &__svg{
        svg {
          height: 40px;
          path {
            stroke: transparent;
            fill: $secondary-color; 
          }
        }
      }
    }

    &__text{
      font-size: 14px;
      &__title{
        font-size: 16px;
        font-weight: 500px;
        margin-bottom: 7px;
      }
      &__data{
        display: flex;
        flex-direction: row;
        color: $oxford;
        &__ref{
          color: $primary_color;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .ActiveCard {
    width: 100%;
  }
}