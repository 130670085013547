@import '../../theme.scss';

.WeeklyCalendar {
    h2 {

        font-family: $main-font;
        font-size: 22px;
        font-weight: 600;
        text-align: left;
        color: $dark;
    }
    .weekCalendar {
        &__content {
            max-height: calc(100vh - 246px);
            min-height: 408px;
        }
        &__header {
            line-height: 18px;
        }
        &__header, &__scaleHeader {
            height: 55px !important;
        }
        &__scaleHeader {
            background-color: white;
            border: none;
        }
        &, &__scaleColumn {
            padding-top: 55px;
        }
        &__headerColumn {
            position: relative;
            &:first-child {
                border-left: 1px solid #d5d8e5;
            }
            .title {
                text-transform: uppercase;
                word-spacing: 100vw;
                font-size: 12px;
                font-weight: normal;
                color: #6c7281;
                left: 50%;
                top: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }
        .calendarBody__cell, &__scaleCell, .calendarBody__column, &__scaleColumn {
            border-color: #f0f0f0;
        }
        &__headerColumn, &__headerWrapper {
            border-color: #d5d8e5;
        }
        &__scaleColumn {
            border-left: 0;        
            &__scaleCell {
                border-color: transparent;
            }
        }
    }
    &.day {
        .weekCalendar__header, .weekCalendar__headerWrapper, .weekCalendar__headerColumn {
            width: 100% !important;
        }
    }
}
    