@import 'src/theme.scss';

.Quota{
  &__container{
    padding: 70px 20px;
    display: flex;
    flex-direction: column;
  }
  &__options{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    button {
      background-image: linear-gradient(to bottom, $secondary-color, $secondary-color);
      margin-bottom: 28px;
    }
  }
}
@media (max-width: 900px) {
  .Quota{
    &__container{
      padding: 60px 0 20px 0;
    }
    .QuotaDetail__drawer {
      width: 100%;
      min-height: auto;
    }
    .QuotaDetail__content {
      padding: 25px;
    }
    .QuotaDetail__col {
      max-width: 100vw;
    }
  }
  .RelationsAmenity__flexCol {
    justify-content: center;
  }
  .RelationsAmenity__amenities {
    overflow: initial;
    flex-wrap: wrap;
    .RelationsAmenityItem {
      span {
        display: none;
      }
    }
  }
}