@import 'src/theme.scss';

.theBudgetConcept{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 17px 0px;
  border-bottom: 1px solid $gray;

  label {
    margin-left: 15px;
  }

  &__normal{
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    img{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  &__center{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &__pointer{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  &__amount{
    flex: 1;
    input{
      width: 100%;
      height: 30px;
      padding: 0px 10px;
      box-sizing: border-box;
      border: 1px solid $soft-gray;
      border-radius: 5px;
      font-size: 14px;
      text-align: center;
    }
    input:disabled, input[disabled]{
      border: none;
      color: $primary_color;
      font-weight: 500;
    }
  }
  &__status{
    flex: 1;
    display: flex;
    align-items: center;
    &__0{
      color: red;
      &:before{
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: red;
      }
    }
    &__1{
      color: $light-orange;
      &:before{
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: $light-orange;
      }
    }
    &__2{
      color: $secondary-color;
      &:before{
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: $secondary-color;
      }
    }
    &__5{
      color: $secondary-color;
      &:before{
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: $secondary-color;
      }
    }
    &__3{
      color: $light-orange;
      &:before{
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: $light-orange;
      }
    }
    &__4{
      color: red;
      &:before{
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: red;
      }
    }
  }
  &__checkbox{
    flex: 1;
    display: flex;
    align-items: center;
    max-width: 120px;
  }
  &__validate{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    button{
      border-radius: 12px !important;
      padding: 0px !important;
      height: 24px !important;
      width: 95px !important;
      min-width: 95px !important;
      min-height: 24px !important;
      aside{
        &:before{
          content: '';
          display: inline-block;
          width: 10px;
          height: 5px;
          border-bottom: 2px solid $total-white;
          border-left: 2px solid $total-white;
          transform: rotate(-50deg);
          bottom: 4px;
          position: relative;
          margin-right: 4px;
        }
      }
    }
    button:disabled, button[disabled]{
      border: none;
      background-color: $primary_color;
      color: $total-white;
      font-weight: 200;
    }
  }
  &__money{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &__negative{
      color: red;
    }
  }
  &__options{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    button{
      padding: 0px;
    }
  }
}