@import 'src/theme.scss';

.RelationItem{
  width: 100%;
  border: 1px solid rgba($primary_color,0.3);
  border-radius: 4px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

  &__image{
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    img{
      height: 100%;
    }
  }
  &__data{
    flex: 1;
    display: flex;
    flex-direction: column;

    &__name{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__name{
        flex: 1;
        margin-bottom: 10px;
        label{
          color: $primary_color;
          font-size: 20px;
          margin-right: 5px;
        }
        span{
          font-size: 18px;
          color: $oxford;
        }
      }
      &__edit{
        width: 70px;
        color: $secondary_color;
        font-size: 14px;
        cursor: pointer;
      }
    }
    &__info{
      display: flex;
      flex-direction: row;

      &__date{
        display: flex;
        flex-direction: row;

        &__icon{
          margin-right: 15px;
          svg{
            height: 30px;
            path{
              fill: $oxford;
            }
          }
        }
        &__info{
          display: flex;
          flex-direction: column;
          margin-right: 10px;

          label{
            color: $primary_color;
            font-size: 12px;
          }
          span{
            color: $oxford;
            font-size: 16px;
          }
        }
      }
    }
  }
}