@import 'src/theme.scss';

.ModalCancel {

  &__title{
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid $soft-gray;
    text-align: center;
    align-items: center;
  }

  .body{
    align-items: center;
    margin-bottom: 15px;
    text-align: center;


    .form {
      align-items: center;
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .success {
    margin: 25px 0 0 0;

    img {
      margin-bottom: 15px;
    }
  }

  &__options{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 90px;

    Button {
      margin: 0 auto;
    }
  }

}
.success img{
  width: 68px;
  height: 55px;
}

.image-worry img{
  width: 58px;
  height: 58px;
  margin-top: 25px;
}

.text-justify{
  text-align: center;
}

.space-regular{
  margin-top: 27px;
}

.SimpleModal-paper-51 {
  top: 25% !important;
}

.SimpleModal-paper-1 {
  top: 25% !important;
}

@media (max-width: 900px){
  .SimpleModal-paper-43{
    width: 80% !important;
    height: 36%;
  }
}
