@import 'src/theme.scss';
.AmenityListItem{
  padding: 5px;
  font-size: 12px;
  color: #6c7281;
  position: relative;
  height: 90px;
  margin: 0px 23px;
  transition-duration: 0.2s;
  &__container {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
    &__options { 
      display: flex;
      &__edit { 
        margin-top: 4px;
        margin-right: 10px;
        cursor: pointer;
      }
      &__delete {
        margin: 0px 5px;
        cursor: pointer;
      }
    }
    &__name{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__text {
        margin: 5px 15px;
      }
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    svg {
      height: 35px;
      max-width: 35px;
    }
  }

  &__option{
    display: flex;
    flex-direction: column;
    text-align: center;
    word-wrap: break-word;
    width: -webkit-fill-available;
  }

  &__edit{
    display: flex;
    justify-content: space-around;
    &__opc{
      width: 50%;
      color: $secondary-color;
      font-size: 14px;
      cursor: pointer;
    }

  }

  &__check{
    position: absolute !important;
    top: 5px !important;
    right: 5px !important;
  }
}

.AmenityListItem__container__amenidad__svg{
  div {
    svg {
      height: 35px;
      max-width: 35px;
    }
  }
}