@import '../../../../theme.scss';

.PayError {
  align-content: center;
  display: flex;
  flex-direction: column;
  padding: 5px;
  text-align: center;
  margin: 20px;
  height: 90%;
  &__text {
    margin-top: 20px;
  }

  &__icono {
    width: 140px;
    height: 140px;
    margin: 0 auto;
  }

  &__message {
    font-family: $main-font;
    font-weight: bold;
    font-size: 16px;
    color: $oxford;
  }

  &__content {
    justify-content: center;
    margin-top: 100px;
  }
}
