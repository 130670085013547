@import 'src/theme.scss';

.ProfilePage{
  height: 100%;
  padding: 20px;
  &__body{
    height: 90%;
    background-color: $total-white;
    border-top: 1px solid $eggshell-blue;

    &__options{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      
      label{
        margin: 0px 20px;
        color: $oxford;
      }
    }
  }
}
.inactive-modal  {
  h3 {
    margin: 30px 0;
  }
  text-align: center;
}
