@import 'src/theme.scss';

.BudgetConceptItem{
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba($primary_color,0.2);

  .MuiOutlinedInput-root {
    width: 100%;
    .MuiInputBase-input  {
      padding: 6px 12px;
    }
  }
  &__col{
    width: 20%;
    padding: 5px 5px;
    max-width: 180px;
    box-sizing: border-box;
    &.col-3 {
      width: 30%;
      max-width: 30%;
    }
    &__input{
      width: 100%;
      height: 30px;
      border: 1px solid $soft-gray;
      background-color: $total-white;
      font-size: 14px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 0px 10px;
      box-sizing: border-box;
      color: $dark;
      cursor: pointer;
    } 
  }
  &__remember{
    width: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__delete{
    width: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.disable{
  color: gray;
  cursor: auto;
}