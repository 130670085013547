@import 'src/theme.scss';

.HistoryItem {
  display: flex;
  flex-direction: row;
  border-right: 1px solid $eggshell-blue;
  border-left: 1px solid $eggshell-blue;
  border-top: 2px solid $light-gray;
  
  &__last{
    border-bottom: 1px solid $eggshell-blue;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  div{
    width: 25%;
    height: 35px;
    padding: 3px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  &__col{
    padding-left: 20px !important;
    box-sizing: border-box;
  }

  &__img{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    img{
      height: 21px;
      max-width: 21px;
    }
  }
}