@import 'src/theme.scss';

.RelationsAmenity{
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;

  &__flexCol{
    justify-content: left;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    margin-bottom: 10px;
    width: 100%;
    align-items: center;
  }

  .SimpleCard {
    padding: 0;
  }

  &__detail{
    width: 100%;
    cursor: pointer;
    color: $secondary-color;
    justify-content: center !important;
    /* padding-right: 30px; */
    text-align: center;
  }
  &__amenities{
    //width: calc(100vw - 240px);
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
    overflow: scroll;
  }

  &__resume{
    //width: 240px;
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    border-radius: 4px;

    &__divider{
      width: 120px;
      height: 0.8px;
      border-radius: 0.8px;
      background-color: $soft-gray;
      margin: 0 auto;

    }

    span{
      width: 54px;
      font-size: 24px;
      color: $primary_color;
      text-align: center;
    }

    &__title{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 24px;
      font-size: 12px;
      background-color: $eggshell-blue;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    
    &__number{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      color: $dark-green;
      height: 78px;
      margin-top: 0px;
      margin-bottom: 3px;
    }
    &__subtitle{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 900;
      color: $dark-green;
      margin-top: 6px;
      margin-bottom: 3px;
    }
  }
}

@media(max-width: 900px){
  .RelationsAmenity{
    display: none;
  }
}