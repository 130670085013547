@import 'src/theme.scss';
.scroll-tab {
    max-width: calc(100vw - 70px);
}
.Tabs {
    display: flex !important;
    align-items: center !important;
    .svg{
        div{
            svg {
                height: 25px;
                width: 28px;
                transition-duration: 0.3s;
            }
        }
    }
    .span { 
        font-family: $main-font;
        font-weight: 400;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }
}