@import 'src/theme.scss';

.Privacy{
  display: contents;
  justify-content: center;
  align-items: center;

  &__container{
    width: 100%;
    max-width: 1000px;
    padding: 0px 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    color: $oxford;
    p{
      margin-top: 20px;
      text-align: justify
    }
    h2{
      margin-top: 20px;
    }
    h3{
      margin-top: 20px;
    }
  }
}