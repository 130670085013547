@import 'src/theme.scss';

.NeighborDetail{
  padding: 30px 20px;
  box-sizing: border-box;
  flex: 1;
  &__back { 
    color: $secondary-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }
  &__title{
    color: $dark;
    font-size: 20px;
    margin-bottom: 20px;
  }
  &__panel{
    padding: 10px;
    padding-bottom: 0px;
  }

  @media (max-width: 900px) { 
    .RelationItem{
      overflow: auto;
    }

    .NeighborPanel{
      overflow: auto;

    }
    .Balance{
      overflow: auto;
    }

    .BalanceHeader{
       min-width: 1000px;
    }

    .BalanceItem{ 
      min-width: 1000px;
    }
  }
}