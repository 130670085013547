@import 'src/theme.scss';

.Bookings {
    width: 100%;
    display: flex;
    flex-direction: row;
    &__fixed {
        position: fixed;
        width: 100%;
        z-index: 1;
        display: flex;
        flex-direction: row;
    }
    &__row { 
        width: 100%;
        margin-top: 65px;
    }
}

.ant-tabs-tab-active {
    color: $dark !important;
    svg {
        height: 25px;
        width: 28px;
        transition-duration: 0.3s;
        * {
            fill: $primary_color !important;
            mask: initial !important;
        }
    }
}

.ant-tabs-bar, .ant-tabs-top-bar  {
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    background-color: white;
    border-top: 1px solid $soft-gray;
}
.ant-tabs-ink-bar {
    background-color: $primary-color !important;
    height: 3px !important;
}

.ant-tabs-tab:hover {
    color: $dark !important;
}

.ant-tabs-tab { 
    margin: 0 10px 0 0 !important;
}

.ant-tabs-nav-container {
    line-height: 0.2 !important;
}