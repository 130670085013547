@import 'src/theme.scss';

.pending-colors {
    background-color: $orange-opacity;
    color: $light-orange;
    &.bordered {
        border-color: $light-orange;
    }
}

.inactive-colors {
    background-color: #e8e8e8;
    color: #000000;
    &.bordered {
        border-color: #000000;
    }
}

.approved-colors {
    background-color: $eggshell-blue;
    color: $primary-color;
    &.bordered {
        border-color: $primary-color;
    }
}
.paid-colors {
    background-color: $blue-opacity;
    color: $light-blue;
    &.bordered {
        border-color: $light-blue;
    }
}
.pending_payment-colors {
    background-color: $orange-opacity;
    color: $light-orange;
    &.bordered {
        border-color: $light-orange;
    }
}

.payed-colors {
    background-color: $blue-opacity;
    color: $light-blue;
    &.bordered {
        border-color: $light-blue;
    }
}
.rejected-colors {
    background-color: $soft-red;
    color: $red;
    &.bordered {
        border-color: $red;
    }
}
.cancelled-colors {
    background-color: $gray-5;
    color: $oxford;
    &.bordered {
        border-color: $oxford;
    }
}

.pending-solid-colors {
    color: $orange-opacity;
    background-color: $light-orange;
}
.approved-solid-colors {
    color: $eggshell-blue;
    background-color: $primary-color;
}
.pending_payment-solid-colors {
    color: $orange-opacity;
    background-color: $light-orange;

}
.payed-solid-colors {
    color: $blue-opacity;
    background-color: $light-blue;
}
.rejected-solid-colors {
    color: $soft-red;
    background-color: $red;
}
.paid-solid-colors {
    color: $blue-opacity;
    background-color: $light-blue;
}