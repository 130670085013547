@import 'src/theme.scss';

.SimpleModal {
    .modal-card {
        max-width: 900px;
        width: calc(90% - 64px);
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: calc(100% - 20px);
        overflow: auto;
        border-radius: 17px;
        outline: none;
        position: absolute;
        background: $total-white;
        padding: 16px;
        box-shadow: 0 2px 7px 0 rgba(142, 150, 158, 0.2);
        & > button {
            position: absolute;
            right: 15px;
            top: 17px;
            width: 30px;
            height: 30px;
            min-height: 0;
            box-shadow: none;        
            svg {
                width: 15px;
                height: 15px;
            }
        }
        .modal-head {
            border-bottom: 1px solid #d5d8e5;
            padding: 0 0 10px;
            h3 {
                margin: 0;
                text-align: center;
            }
        }
        .modal-body {
            padding: 20px;
            button + button {
                margin-left: 10px;
            }
        }
        hr {
            height: 1px;
            border-width: 0;
            color: $soft-gray;
            background-color: $soft-gray;
            margin: 20px 0;
        }
    }
}