@import 'src/theme.scss';

.IframeFiservButton button {
    width: 452px;
    height: 42px;
    border-radius: 4px;
    background-color: $primary-color;
    margin: 10px 0px;
    color: $total-white;
    font-family: $main-font;
    cursor: pointer;
    border: none;
    font-size: 16px;
}

iframe { 
    border: 1px solid  $light-gray;
    border-radius: 6px;
    width: 96%;
    height: 650px;
    outline: 0;
    margin: 10px 0px;
}
